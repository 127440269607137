import React, { FC } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import GlobalProvider from './context/global.context';
import RoleProvider from './context/roleManagment.context';

import {
  LoginPage,
  Dashboard,
  ForbiddenPage,
  NotFoundPage,
  Users,
  UserForm,
  Roles,
  RolesForm,
  BuildingSite,
  BuildingSiteForm,
  Vehicle,
  VehicleForm,
  VehicleKind,
  VehicleKindForm,
  VehicleServices,
  VehicleServiceForm,
  Transfer,
  TransferForm,
  Warning,
} from './pages';
import PrivateRoute from './components/PrivateRoute';
import './assets/style/app.less';

const App: FC = () => {
  return (
    <GlobalProvider>
      <RoleProvider>
        <Router>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <PrivateRoute
              exact
              path="/"
              component={() => <Redirect to="/dashboard" />}
            />
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute
              exact
              path="/users"
              component={Users}
              title={{
                main: 'Kullanıcı',
                subTitle: 'Listesi',
              }}
            />
            <PrivateRoute
              exact
              path="/users/add"
              component={UserForm}
              title={{
                main: 'Kullanıcı',
                subTitle: 'Ekle',
              }}
            />
            <PrivateRoute
              exact
              path="/users/edit/:id"
              component={UserForm}
              title={{
                main: 'Kullanıcı',
                subTitle: 'Düzenle',
              }}
            />
            <PrivateRoute
              exact
              path="/roles"
              component={Roles}
              title={{
                main: 'Rol',
                subTitle: 'Listesi',
              }}
            />
            <PrivateRoute
              exact
              path="/roles/add"
              component={RolesForm}
              title={{
                main: 'Rol',
                subTitle: 'Ekle',
              }}
            />
            <PrivateRoute
              exact
              path="/roles/edit/:id"
              component={RolesForm}
              title={{
                main: 'Rol',
                subTitle: 'Düzenle',
              }}
            />
            <PrivateRoute
              exact
              path="/buildingSite"
              component={BuildingSite}
              title={{
                main: 'Şantiye',
                subTitle: 'Listesi',
              }}
            />

            <PrivateRoute
              exact
              path="/buildingSite/add"
              component={BuildingSiteForm}
              title={{
                main: 'Şantiye',
                subTitle: 'Ekle',
              }}
            />
            <PrivateRoute
              exact
              path="/buildingSite/edit/:id"
              component={BuildingSiteForm}
              title={{
                main: 'Şantiye',
                subTitle: 'Düzenle',
              }}
            />

            <PrivateRoute
              exact
              path="/vehicle"
              component={Vehicle}
              title={{
                main: 'Araç',
                subTitle: 'Listesi',
              }}
            />
            <PrivateRoute
              exact
              path="/vehicle/add"
              component={VehicleForm}
              title={{
                main: 'Araç',
                subTitle: 'Ekle',
              }}
            />
            <PrivateRoute
              exact
              path="/vehicle/edit/:id"
              component={VehicleForm}
              title={{
                main: 'Araç',
                subTitle: 'Düzenle',
              }}
            />
            <PrivateRoute
              exact
              path="/vehicle-kind"
              component={VehicleKind}
              title={{
                main: 'Araç Cinsi',
                subTitle: 'Listesi',
              }}
            />
            <PrivateRoute
              exact
              path="/vehicle-kind/add"
              component={VehicleKindForm}
              title={{
                main: 'Araç Cinsi',
                subTitle: 'Ekle',
              }}
            />
            <PrivateRoute
              exact
              path="/vehicle-kind/edit/:id"
              component={VehicleKindForm}
              title={{
                main: 'Araç Cinsi',
                subTitle: 'Düzenle',
              }}
            />
            <PrivateRoute
              exact
              path="/vehicle/services/:id"
              component={VehicleServices}
              title={{
                main: 'Araç Onarım ve Bakım',
                subTitle: 'Liste',
              }}
            />
            <PrivateRoute
              exact
              path="/vehicle/services/:id/add"
              component={VehicleServiceForm}
              title={{
                main: 'Araç Onarım ve Bakım',
                subTitle: 'Ekle',
              }}
            />
            <PrivateRoute
              exact
              path="/transfer"
              component={Transfer}
              title={{
                main: 'Sevk',
                subTitle: 'Listesi',
              }}
            />
            <PrivateRoute
              exact
              path="/transfer/add"
              component={TransferForm}
              title={{
                main: 'Sevk',
                subTitle: 'Ekle',
              }}
            />
            <PrivateRoute
              exact
              path="/warning"
              component={Warning}
              title={{
                main: 'ihlaller',
                subTitle: 'Listesi',
              }}
            />

            <Route path="/404" component={NotFoundPage} />
            <Route path="/403" component={ForbiddenPage} />
            <Route component={() => <Redirect to="/404" />} />
          </Switch>
        </Router>
      </RoleProvider>
    </GlobalProvider>
  );
};

export default App;
