import React, { FC, ReactNode, useState, useContext, useEffect } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '../../context/global.context';
import { RoleManagmentContext } from '../../context/roleManagment.context';
import LoadingOverlay from 'react-loading-overlay-ts';
import { parseJwt } from '../../helpers';
import { authenticationService } from '../../servives';
import getFistLetter from '../../utils/getFistLetter';
import { ParsedJwtModel } from '../../helpers/decodeJwt';

//
import {
  Layout,
  Menu,
  Dropdown,
  Avatar,
  PageHeader,
  Modal,
  Form,
  message,
  Input,
} from 'antd';
import {
  CarOutlined,
  PieChartOutlined,
  LogoutOutlined,
  HomeOutlined,
  UserOutlined,
  ControlOutlined,
  LockOutlined,
  SendOutlined,
  NotificationOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';
import { CustomButton, CustomSelect } from '../../components/FormElements';
//
import logo from '../../assets/images/logo.png';
import createSelectOptions from '../../utils/createSelectOptions';

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    margin: 10px 0 15px 0;
  }
`;

const CustomHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  color: #ffffff;
  margin-right: 10px;

  .ant-select {
    min-width: 200px;
    margin-right: 20px;
  }
`;

const ResetPasswordActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

interface DefaultLayout {
  children: ReactNode;
  title: {
    main: string;
    subTitle: string;
  };
}
interface ReserPasswordForm {
  oldPassword: string;
  newPassword: string;
}
// TODO: Repassword sayfasi gelicek

const DefaultLayout: FC<DefaultLayout> = ({
  children,
  title,
}: DefaultLayout) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [token, setToken] = useState<ParsedJwtModel>();
  const history = useHistory();
  const location = useLocation();

  const {
    loadingCount,
    userUnits,
    selectedUnitId,
    handleSelectedUnit,
  } = useContext(GlobalContext);
  const { roles } = useContext(RoleManagmentContext);

  const [collapsed, setCollapsed] = useState(false);
  const { Header, Content, Sider } = Layout;

  useEffect(() => {
    const token: any = localStorage.getItem('makimsanToken');
    const parsedJwt: ParsedJwtModel = parseJwt(token);
    setToken(parsedJwt);
  }, []);

  const onCollapse = (collapsed: boolean) => {
    setCollapsed(collapsed);
  };

  const handleLogOut = () => {
    authenticationService.logout();
    history.push('/login');
  };

  const handleResetPasswordModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleResetPassword = (value: ReserPasswordForm) => {
    const token: any = localStorage.getItem('makimsanToken');
    const parsedJwt = parseJwt(token);
    authenticationService
      .resetPassword({
        id: parsedJwt.userId,
        ...value,
      })
      .then(() => {
        message.success('İşlem Başarılı.');
        authenticationService.logout();
        history.push('/login');
      })
      .catch((err) => {
        console.error('ERROR=>', err);
      });
  };

  const handleGoBack = () => {
    history.go(-1);
  };

  const userMenu = (
    <Menu>
      <Menu.Item icon={<LockOutlined />} key="rePassword">
        <a onClick={handleResetPasswordModal}>Şifre Değiştir</a>
      </Menu.Item>
      <Menu.Item icon={<LogoutOutlined />} key="logout">
        <a onClick={handleLogOut}>Çıkış Yap</a>
      </Menu.Item>
    </Menu>
  );

  const handleFindLocation = () => {
    let path = location.pathname.split('/')[1];
    if (path === 'vehicle-kind') {
      path = 'vehicle';
    }

    return path;
  };

  return (
    <LoadingOverlay active={loadingCount > 0} spinner text="Yükleniyor...">
      <Layout style={{ minHeight: '100vh' }}>
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
          <LogoWrapper>
            <img src={logo} />
          </LogoWrapper>
          <Menu
            theme="dark"
            defaultSelectedKeys={['1']}
            mode="inline"
            selectedKeys={[`${handleFindLocation()}`]}
          >
            {roles.includes('LIST_USERS') && (
              <Menu.Item icon={<HomeOutlined />} key="dashboard">
                <Link to="/dashboard">AnaSayfa</Link>
              </Menu.Item>
            )}
            {roles.includes('LIST_USERS') && (
              <Menu.Item icon={<UserOutlined />} key="users">
                <Link to="/users">Kullanıcılar</Link>
              </Menu.Item>
            )}
            {roles.includes('LIST_ROLES') && (
              <Menu.Item icon={<ControlOutlined />} key="roles">
                <Link to="/roles">Roller</Link>
              </Menu.Item>
            )}
            {roles.includes('LIST_UNITS') && (
              <Menu.Item key="buildingSite" icon={<PieChartOutlined />}>
                <Link to="/buildingSite">Şantiye</Link>
              </Menu.Item>
            )}
            {roles.includes('LIST_VEHICLES') && (
              <Menu.Item key="vehicle" icon={<CarOutlined />}>
                <Link to="/vehicle">Araçlar</Link>
              </Menu.Item>
            )}
            {roles.includes('LIST_TRANSFERS') && (
              <Menu.Item key="transfer" icon={<SendOutlined />}>
                <Link to="/transfer">Sevk</Link>
              </Menu.Item>
            )}
            {roles.includes('LIST_WARNINGS') && (
              <Menu.Item key="warning" icon={<NotificationOutlined />}>
                <Link to="/warning">İhlaller</Link>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <CustomHeader>
              {selectedUnitId !== 0 && (
                <CustomSelect
                  placeholder="Şantiye"
                  options={createSelectOptions(userUnits, 'name', 'id')}
                  defaultValue={selectedUnitId}
                  onChange={(e: any) => {
                    handleSelectedUnit(e);
                  }}
                />
              )}
              <Dropdown
                overlay={userMenu}
                placement="bottomRight"
                arrow
                trigger={['click']}
              >
                <Avatar>
                  {getFistLetter(`${token?.name} ${token?.surname}`)}
                </Avatar>
              </Dropdown>
            </CustomHeader>
          </Header>
          <Content style={{ margin: '12px' }}>
            {location.pathname !== '/dashboard' && (
              <PageHeader
                className="site-page-header"
                onBack={handleGoBack}
                title={title.main}
                subTitle={title.subTitle}
              />
            )}

            <div className="site-layout-background">{children}</div>
          </Content>
        </Layout>
      </Layout>
      {isModalVisible && (
        <Modal
          title="Şifre Değiştir"
          visible={isModalVisible}
          onCancel={handleResetPasswordModal}
          footer={null}
        >
          <Form
            name="reset_password"
            onFinish={handleResetPassword}
            layout="vertical"
          >
            <Form.Item
              name="oldPassword"
              label="Eski Şifre"
              rules={[{ required: true, message: 'Eski Şifre Giriniz!' }]}
            >
              <Input.Password autoComplete="off" placeholder="Eski Şifre" />
            </Form.Item>
            <Form.Item
              name="newPassword"
              label="Yeni Şifre"
              rules={[{ required: true, message: 'Yeni Şifre Giriniz!' }]}
            >
              <Input.Password autoComplete="off" placeholder="Yeni Şifre" />
            </Form.Item>
            <ResetPasswordActionWrapper>
              <Form.Item>
                <CustomButton type="primary" htmlType="submit" color="dark">
                  Vazgeç
                </CustomButton>
              </Form.Item>
              <Form.Item style={{ marginLeft: '10px' }}>
                <CustomButton type="primary" htmlType="submit">
                  Şifre Değiştir
                </CustomButton>
              </Form.Item>
            </ResetPasswordActionWrapper>
          </Form>
        </Modal>
      )}
    </LoadingOverlay>
  );
};
// export const FullWidthLayout = ({ children }: any) => <div>{children}</div>;

export default DefaultLayout;
