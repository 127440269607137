const city = [
  {
    label: 'Adana',
    value: 1,
  },
  {
    label: 'Adiyaman',
    value: 2,
  },
  {
    label: 'Afyonkarahisar',
    value: 3,
  },
  {
    label: 'Ağrı',
    value: 4,
  },
  {
    label: 'Amasya',
    value: 5,
  },
  {
    label: 'Ankara',
    value: 6,
  },
  {
    label: 'Antalya',
    value: 7,
  },

  {
    label: 'Artvin',
    value: 8,
  },
  {
    label: 'Aydın',
    value: 9,
  },
  {
    label: 'Balikesir',
    value: 10,
  },

  {
    label: 'Bilecik',
    value: 11,
  },
  {
    label: 'Bingöl',
    value: 12,
  },
  {
    label: 'Bitlis',
    value: 13,
  },
  {
    label: 'Bolu',
    value: 14,
  },
  {
    label: 'Burdur',
    value: 15,
  },

  {
    label: 'Bursa',
    value: 16,
  },
  {
    label: 'Çanakkale',
    value: 17,
  },
  {
    label: 'Çankırı',
    value: 18,
  },
  {
    label: 'Çorum',
    value: 19,
  },
  {
    label: 'Denizli',
    value: 20,
  },
  {
    label: 'Diyarbakır',
    value: 21,
  },
  {
    label: 'Edirne',
    value: 22,
  },
  {
    label: 'Elazığ',
    value: 23,
  },
  {
    label: 'Erzincan',
    value: 24,
  },
  {
    label: 'Erzurum',
    value: 25,
  },
  {
    label: 'Eskişehir',
    value: 26,
  },
  {
    label: 'Gaziantep',
    value: 27,
  },
  {
    label: 'Giresun',
    value: 28,
  },
  {
    label: 'Gümüşhane',
    value: 29,
  },
  {
    label: 'Hakkari',
    value: 30,
  },
  {
    label: 'Hakkari',
    value: 31,
  },
  {
    label: 'Isparta',
    value: 32,
  },
  {
    label: 'Mersin',
    value: 33,
  },
  {
    label: 'İstanbul',
    value: 34,
  },
  {
    label: 'İzmir',
    value: 35,
  },

  {
    label: 'Kars',
    value: 36,
  },

  {
    label: 'Kastamonu',
    value: 37,
  },
  {
    label: 'Kayseri',
    value: 38,
  },
  {
    label: 'Kırklareli',
    value: 39,
  },

  {
    label: 'Kırşehir',
    value: 40,
  },

  {
    label: 'Kocaeli',
    value: 41,
  },

  {
    label: 'Konya',
    value: 42,
  },
  {
    label: 'Kütahya',
    value: 43,
  },
  {
    label: 'Malatya',
    value: 44,
  },

  {
    label: 'Manisa',
    value: 45,
  },

  {
    label: 'Kahramanmaraş',
    value: 46,
  },
  {
    label: 'Mardin',
    value: 47,
  },

  {
    label: 'Muğla',
    value: 48,
  },

  {
    label: 'Muş',
    value: 49,
  },

  {
    label: 'Nevşehir',
    value: 50,
  },
  {
    label: 'Niğde',
    value: 51,
  },
  {
    label: 'Ordu',
    value: 52,
  },

  {
    label: 'Rize',
    value: 53,
  },

  {
    label: 'Sakarya',
    value: 54,
  },

  {
    label: 'Samsun',
    value: 55,
  },
  {
    label: 'Siirt',
    value: 56,
  },

  {
    label: 'Sinop',
    value: 57,
  },

  {
    label: 'Sivas',
    value: 58,
  },
  {
    label: 'Tekirdağ',
    value: 59,
  },

  {
    label: 'Tokat',
    value: 60,
  },

  {
    label: 'Trabzon',
    value: 61,
  },

  {
    label: 'Tunceli',
    value: 62,
  },
  {
    label: 'Şanlıurfa',
    value: 63,
  },

  {
    label: 'Uşak',
    value: 64,
  },

  {
    label: 'Van',
    value: 65,
  },

  {
    label: 'Yozgat',
    value: 66,
  },
  {
    label: 'Zonguldak',
    value: 67,
  },
  {
    label: 'Aksaray',
    value: 68,
  },

  {
    label: 'Bayburt',
    value: 69,
  },
  {
    label: 'Karaman',
    value: 70,
  },

  {
    label: 'Kırıkkale',
    value: 71,
  },

  {
    label: 'Batman',
    value: 72,
  },

  {
    label: 'Şırnak',
    value: 73,
  },

  {
    label: 'Bartın',
    value: 74,
  },

  {
    label: 'Ardahan',
    value: 75,
  },
  {
    label: 'Iğdır',
    value: 76,
  },
  {
    label: 'Yalova',
    value: 77,
  },

  {
    label: 'Karabük',
    value: 78,
  },
  {
    label: 'Kilis',
    value: 79,
  },
  {
    label: 'Osmaniye',
    value: 80,
  },

  {
    label: 'Düzce',
    value: 81,
  },
];

export { city };
