import React, { FC, useState, useEffect, useContext } from 'react';
import { vehicleCounts } from '../../servives/graph/graph.service';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { CustomLegendWrapper, CustomTootipWrapper } from './Graph.styled';
import { GlobalContext } from '../../context/global.context';
interface BarChartModel {
  name: string;
  uv: number;
  pv: number;
  amt: number;
}

const Colors = ['#8884d8', '#82ca9d', '#ffc658'];

const UnitsVehiclesGraph: FC = () => {
  const [barCharData, setBarChartData] = useState<BarChartModel[]>([]);
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  useEffect(() => {
    increaseLoading();

    vehicleCounts()
      .then((resp) => {
        decreaseLoading();

        const data = resp.data.map((item) => {
          const newKindCounts: any = {
            name: item.unit,
            TOTAL: item.totalVehicleCount,
          };
          item.kindCounts.forEach((kinsCount) => {
            newKindCounts[kinsCount.type] = kinsCount.count;
          });
          return newKindCounts;
        });
        setBarChartData(data);
      })
      .catch((err) => {
        decreaseLoading();
        console.error('ERROR=>', err);
      });
  }, []);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <CustomTootipWrapper>
          <p className="label">{label}</p>
          {payload.map((item: any, idx: number) => (
            <p
              key={`${item.name}-${idx}`}
              className="label"
              style={{ color: `${Colors[idx]}` }}
            >{`${handleVehicleText(item.name)} : ${item.value}`}</p>
          ))}
        </CustomTootipWrapper>
      );
    }

    return null;
  };

  const handleVehicleText = (type: string) => {
    if (type === 'VEHICLE') {
      return 'Araç';
    } else if (type === 'EQUIPMENT') {
      return 'İş Makinesi';
    } else if (type === 'TOTAL') {
      return 'Toplam';
    }
  };

  const CustomLegend = ({ payload }: any) => {
    return (
      <CustomLegendWrapper>
        {payload?.map((entry: any, index: any) => (
          <div className="customLegend" key={`item-${index}`}>
            <div
              className="square"
              style={{ backgroundColor: `${Colors[index]}` }}
            />
            {handleVehicleText(entry.value)}
          </div>
        ))}
      </CustomLegendWrapper>
    );
  };

  return (
    <ResponsiveContainer width="90%" height={400}>
      <BarChart
        data={barCharData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis allowDecimals={false} />
        <Tooltip content={<CustomTooltip />} />
        <Legend content={<CustomLegend />} />
        <Bar dataKey="VEHICLE" fill="#8884d8" />
        <Bar dataKey="EQUIPMENT" fill="#82ca9d" />
        <Bar dataKey="TOTAL" fill="#ffc658" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default UnitsVehiclesGraph;
