interface DocsType {
  id?: number;
  name?: string;
  description?: string;
  expiryDate?: string;
  type?: string;
  uri?: string;
}

const downloadFile = (file: File, doc: DocsType): any => {
  const blob = new Blob([file], { type: file.type });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `${doc.uri}`;
  link.click();
};

const displayFile = (file: File): any => {
  const blob = new Blob([file], { type: file.type });
  const link = document.createElement('a');
  link.setAttribute('target', '_blank');
  link.href = window.URL.createObjectURL(blob);
  link.click();
};

export { downloadFile, displayFile };
