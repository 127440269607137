import React, { FC, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/global.context';
import { Unit } from '../../servives';
import { UnitCreateModel } from '../../servives/unit/unit.model';
import { city } from '../../helpers';
import moment from 'moment';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

//
import { Row, Col, Form, message } from 'antd';
import styled from 'styled-components';
import {
  CustomInput,
  CustomSelect,
  CustomButton,
  CustomDatePicker,
} from '../../components/FormElements';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface paramTypes {
  id?: string;
}

const BuildingSiteForm: FC = () => {
  const params = useParams<paramTypes>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  useEffect(() => {
    if (params?.id) {
      increaseLoading();
      Unit.getById(params.id)
        .then((resp) => {
          decreaseLoading();
          const {
            name,
            description,
            cityId,
            tenderDate,
            tenderRegistrationNo,
            contractAmount,
            contractDate,
            deliveryDate,
            contractName,
            contractDuration,
            employer,
          } = resp.data;
          form.setFieldsValue({
            name,
            description,
            cityId,
            tenderDate: tenderDate ? moment(tenderDate) : null,
            tenderRegistrationNo,
            contractAmount,
            contractDate: contractDate ? moment(contractDate) : null,
            deliveryDate: deliveryDate ? moment(deliveryDate) : null,
            contractName,
            contractDuration,
            employer,
          });
        })
        .catch((err) => {
          console.error('ERROR=>', err);

          decreaseLoading();
          message.error(err);
        });
    }
  }, []);

  const onFinish = (values: UnitCreateModel) => {
    increaseLoading();

    if (params?.id) {
      // Edit
      Unit.UnitUpdate({ id: params.id, ...values })
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          history.push('/buildingSite');
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    } else {
      // Create
      Unit.UnitCreate(values)
        .then(() => {
          decreaseLoading();
          history.push('/buildingSite');
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    }
  };
  return (
    <div>
      <Form name="units" onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Şantiye Adi"
              rules={[
                { required: true, message: 'Lütfen Şantiye Adı Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Şantiye Adi" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="Açıklama">
              <CustomInput type="text" placeholder="Açıklama" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cityId"
              label="Şehir"
              rules={[{ required: true, message: 'Lütfen Şehir Seçiniz!' }]}
            >
              <CustomSelect
                placeholder="Lütfen Şehir Seçiniz."
                options={city}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  const { label } = option;
                  return label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              ></CustomSelect>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="tenderDate"
              label="İhale Tarih Seçiniz."
              rules={[
                {
                  required: false,
                  message: 'Lütfen İhale Tarih Seçiniz.',
                },
              ]}
            >
              <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tenderRegistrationNo"
              label="İhale Kayıt No"
              rules={[
                { required: false, message: 'Lütfen İhale Kayıt No Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="İhale Kayıt No" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contractAmount"
              label="Sözleşme Bedeli"
              rules={[
                { required: false, message: 'Lütfen Sözleşme Bedeli Giriniz!' },
              ]}
            >
              <CustomInput type="number" placeholder="Sözleşme Bedeli" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contractDate"
              label="Sözleşme Tarih Seçiniz."
              rules={[
                {
                  required: false,
                  message: 'Lütfen Sözleşme Tarih Seçiniz.',
                },
              ]}
            >
              <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="deliveryDate"
              label="Teslim Tarih Seçiniz."
              rules={[
                {
                  required: false,
                  message: 'Lütfen Teslim Tarih Seçiniz.',
                },
              ]}
            >
              <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contractName"
              label="İşin Adı"
              rules={[
                { required: false, message: 'Lütfen İşin Adını Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="İşin Adı" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contractDuration"
              label="İşin Süresi(gün)"
              rules={[
                { required: false, message: 'Lütfen İşin Süresini Giriniz!' },
              ]}
            >
              <CustomInput type="number" placeholder="İşin Süresi" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="employer"
              label="İş veren"
              rules={[{ required: false, message: 'Lütfen İş veren Giriniz!' }]}
            >
              <CustomInput type="text" placeholder="İş Veren" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <ButtonWrapper>
                <CustomButton type="primary" htmlType="submit">
                  Kaydet
                </CustomButton>
              </ButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BuildingSiteForm;
