import { authHeader, request } from '../../helpers';
import {
  UserCreateModel,
  UserReadDataModel,
  UserUpdateModel,
} from './user.model';
import { ResponseModel, List } from '../models/global.model';

interface UserModel extends List {
  list: UserReadDataModel[];
}
interface UserGetById extends ResponseModel {
  data: UserReadDataModel;
}

interface UserResponseModel extends ResponseModel {
  data: UserModel;
}

function getAll(query?: string): Promise<UserResponseModel> {
  return request({
    url: `/users?${query ? query : ''}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function getById(id: string | number): Promise<UserGetById> {
  return request({
    url: `/users/${id}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function userDelete(id: number): Promise<UserGetById> {
  return request({
    url: `/users/${id}`,
    method: 'DELETE',
    headers: authHeader(),
  });
}

function userCreate(userData: UserCreateModel): Promise<UserResponseModel> {
  return request({
    url: `/users`,
    method: 'POST',
    headers: authHeader(),
    data: { ...userData },
  });
}

function userUpdate(userData: UserUpdateModel): Promise<UserResponseModel> {
  return request({
    url: `/users/${userData.id}`,
    method: 'PUT',
    headers: authHeader(),
    data: { ...userData },
  });
}

export { getAll, getById, userCreate, userUpdate, userDelete };
