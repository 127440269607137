// import { authenticationService } from '../servives';

interface AutHeader {
  Authorization?: string;
}

interface makimsanToken {
  token: string | null;
}

function authHeader(): AutHeader | null | '' {
  const makimsanToken: string | null = localStorage.getItem('makimsanToken');

  if (makimsanToken) {
    const parser: makimsanToken = JSON.parse(makimsanToken);
    return parser.token && { Authorization: `Bearer ${parser.token}` };
  }
  return {};
}

const getToken = (): string | null => {
  const makimsanToken: string | null = localStorage.getItem('makimsanToken');

  if (makimsanToken) {
    const parser: makimsanToken = JSON.parse(makimsanToken);

    return parser.token && parser.token;
  }
  return null;
};

export { authHeader, getToken };
