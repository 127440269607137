import { authHeader, request } from '../../helpers';
import { ResponseModel, List } from '../models/global.model';
import {
  VehicleKindModel,
  CreateVehicleKind,
  UpdateVehicleKind,
} from './vehicleKind.model';

//  VehicleKind

interface VehicleKindListModel extends List {
  list: VehicleKindModel[];
}

interface VehicleKindByIdResponseModel extends ResponseModel {
  data: VehicleKindModel;
}

interface VehicleKindResponseModel extends ResponseModel {
  data: VehicleKindListModel;
}

function getAll(
  type?: string,
  query?: string,
): Promise<VehicleKindResponseModel> {
  return request({
    url: `/vehiclekinds${type ? `?type=${type}` : ''}${
      query ? `?${query}` : ''
    }`,
    method: 'GET',
    headers: authHeader(),
  });
}

function getById(id: string): Promise<VehicleKindByIdResponseModel> {
  return request({
    url: `/vehiclekinds/${id}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function createVehicleKind(
  params: CreateVehicleKind,
): Promise<VehicleKindByIdResponseModel> {
  return request({
    url: `/vehiclekinds`,
    method: 'POST',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
}

function updateVehicleKind(
  params: UpdateVehicleKind,
): Promise<VehicleKindByIdResponseModel> {
  return request({
    url: `/vehiclekinds/${params.id}`,
    method: 'PUT',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
}

function deleteVehicleKind(id: number): Promise<UpdateVehicleKind> {
  return request({
    url: `/vehiclekinds/${id}`,
    method: 'DELETE',
    headers: authHeader(),
  });
}

export {
  getAll,
  getById,
  createVehicleKind,
  updateVehicleKind,
  deleteVehicleKind,
};
