import React, { FC, useContext, useEffect, useState } from 'react';
import {
  UnitsVehiclesGraph,
  UnitsConsumptionGraph,
} from '../../components/Graph';

import { Col, Row } from 'antd';
import {
  CarouselContent,
  CustomCard,
  CustomCarousel,
  CustomCarouselItem,
} from './Dashboard.styled';
import {
  ExpiredDocs,
  VehicleConflicts,
} from '../../servives/graph/graph.service';
import {
  ExpiredDocsModel,
  VehicleConflictsModel,
} from '../../servives/graph/graph.model';
import { GlobalContext } from '../../context/global.context';
import { DocumantTypeEnums } from '../../enum/vehicle';
import { Link } from 'react-router-dom';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const Dashboard: FC = () => {
  const [expiredDocs, setExpiredDocs] = useState<ExpiredDocsModel[]>([]);
  const [vehicleConflicts, setVehicleConflicts] = useState<
    VehicleConflictsModel[]
  >([]);

  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  useEffect(() => {
    increaseLoading();

    ExpiredDocs()
      .then((resp) => {
        const x = resp.data.concat(resp.data, resp.data, resp.data, resp.data);
        setExpiredDocs(x.concat(x));
        decreaseLoading();
      })
      .catch((err) => {
        decreaseLoading();
        console.error('ERROR=>', err);
      });
  }, []);

  useEffect(() => {
    increaseLoading();

    VehicleConflicts()
      .then((resp) => {
        setVehicleConflicts(resp.data);
        decreaseLoading();
      })
      .catch((err) => {
        decreaseLoading();
        console.error('ERROR=>', err);
      });
  }, []);

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Row gutter={10}>
            <Col span={12}>
              <CustomCard title="Araç Belge Durumu" titleColor="#000000">
                <CustomCarousel
                  autoplay
                  dots={false}
                  prevArrow={<LeftOutlined />}
                  nextArrow={<RightOutlined />}
                  arrows
                >
                  {expiredDocs.map(
                    ({ vehicleId, vehicleNo, type, expiryDate }) => {
                      return (
                        <CarouselContent key={vehicleId}>
                          <Link to={`/vehicle/edit/${vehicleId}`}>
                            <span className="btn-back">{vehicleNo}</span>
                          </Link>
                          {` nolu aracın ${DocumantTypeEnums[type]} geçerlilik tarihi: ${expiryDate}`}
                        </CarouselContent>
                      );
                    },
                  )}
                </CustomCarousel>
              </CustomCard>
            </Col>
            <Col span={12}>
              <CustomCard title="Araç Tüketim durumu" titleColor="#000000">
                <CustomCarousel
                  autoplay
                  dots={false}
                  prevArrow={<LeftOutlined />}
                  nextArrow={<RightOutlined />}
                  arrows
                >
                  {vehicleConflicts.map(
                    ({
                      vehicleId,
                      vehicleNo,
                      from,
                      to,
                      avgConsumption,
                      hours,
                      unitId,
                    }) => {
                      return (
                        <CarouselContent key={vehicleId}>
                          <Link
                            to={`/vehicle?&vehicleId=${vehicleId}&unitId=${unitId}`}
                          >
                            <span className="btn-back">{vehicleNo}</span>
                          </Link>

                          {` nolu araçın ${from} - ${to} tarihleri arasında fazla tüketim (Ortalama: ${avgConsumption} lt/${
                            hours ? `s` : `km`
                          })`}
                        </CarouselContent>
                      );
                    },
                  )}
                </CustomCarousel>
              </CustomCard>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={10}>
            <Col span={12}>
              <CustomCard title="Şantiyelerdeki Araç Sayısı" height="545px">
                <CustomCarouselItem>
                  <UnitsVehiclesGraph />
                </CustomCarouselItem>
              </CustomCard>
            </Col>
            <Col span={12}>
              <CustomCard
                title="Şantiyelerdeki Mazot Kullanım Bilgisi"
                height="545px"
              >
                <CustomCarouselItem>
                  <UnitsConsumptionGraph />
                </CustomCarouselItem>
              </CustomCard>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
