import { authHeader, request } from '../../helpers';
import { ResponseModel, List } from '../models/global.model';
import { TransferModel, TransferCreateModel } from './transfer.model';

interface TransferListModel extends List {
  list: TransferModel[];
}

interface TransferListModelResponseModel extends ResponseModel {
  data: TransferListModel;
}
interface TransferCreateModelResponseModel extends ResponseModel {
  data: TransferModel;
}

function getAllByUnitId(
  key: string,
  query?: string,
  filterUnitId?: string,
  unitId?: number,
): Promise<TransferListModelResponseModel> {
  if (filterUnitId) {
    unitId = +filterUnitId;
  }

  return request({
    url: `transfers?${key}=${unitId}${query ? query : ''}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function createTransfer(
  params: TransferCreateModel,
): Promise<TransferCreateModelResponseModel> {
  return request({
    url: `transfers`,
    method: 'POST',
    headers: authHeader(),
    data: params,
  });
}

function approveTransfer(id: number): Promise<TransferListModelResponseModel> {
  return request({
    url: `transfers/${id}/approve`,
    method: 'POST',
    headers: authHeader(),
  });
}

function cancelTransfer(id: number): Promise<TransferListModelResponseModel> {
  return request({
    url: `transfers/${id}/cancel`,
    method: 'POST',
    headers: authHeader(),
  });
}

function downloadTransferPdf(id: number): Promise<any> {
  return request({
    url: `/transfers/pdf/${id}`,
    method: 'POST',
    headers: {
      Accept: 'application/octet-stream',
      ...authHeader(),
    },
    responseType: 'blob',
  });
}

export {
  getAllByUnitId,
  createTransfer,
  approveTransfer,
  cancelTransfer,
  downloadTransferPdf,
};
