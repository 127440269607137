import React, { useState, useEffect, useContext, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Unit } from '../../servives';
import { GlobalContext } from '../../context/global.context';
import { CheckPermission } from '../../HOC';
import { city } from '../../helpers';
import { paginationPropertiesModel } from '../../servives/models/global.model';

//
import styled from 'styled-components';
import { Table, Button, Tooltip, Space, Modal, message } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import { CustomButton } from '../../components/FormElements';

const NewUser = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

interface UnitsData {
  id: number;
  unitId: string;
  name: string;
  description: string;
  cityId: number;
}

const BuildingSite: FC = () => {
  const [units, setUnits] = useState<UnitsData[]>([]);
  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });
  const { increaseLoading, decreaseLoading } = useContext(GlobalContext);
  const history = useHistory();

  const COLUMS = [
    {
      title: 'Şantiye Adı',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Şehir',
      render: (record: UnitsData) => (
        <>
          <Space>
            {city.map((item) => {
              if (item.value === record.cityId) {
                return item.label;
              }
            })}
          </Space>
        </>
      ),
    },
    {
      title: '',
      render: (record: UnitsData) => (
        <>
          <Space>
            <CheckPermission authority="UPDATE_UNITS">
              <Tooltip title="Düzenle">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleUnitEdit(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="DELETE_UNITS">
              <Tooltip title="Sil">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleUnitDelete(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
          </Space>
        </>
      ),
    },
  ];

  const handleGetUnits = (query?: string) => {
    increaseLoading();
    Unit.getAll(query).then((resp) => {
      const { list, total } = resp.data;
      decreaseLoading();
      const unitTableData = list.map((item) => {
        return {
          key: item.id,
          ...item,
        };
      });
      setUnits(unitTableData);
      setPaginationProperties((currentState) => ({
        ...currentState,
        total: total,
      }));
    });
  };

  useEffect(() => {
    handleGetUnits();
  }, []);

  const handleUnitEdit = (id: number) => {
    history.push(`/buildingSite/edit/${id}`);
  };

  const handleUnitDelete = (id: number) => {
    Modal.confirm({
      title: 'Uyari!',
      icon: <ExclamationCircleOutlined />,
      content: 'Şantiyeyi Silmek istedeğinize emin misiniz?',
      okText: 'Sil',
      cancelText: 'Iptal',
      onOk: () => {
        increaseLoading();
        Unit.UnitDelete(id)
          .then(() => {
            decreaseLoading();
            handleGetUnits();
          })
          .catch((err) => {
            decreaseLoading();
            message.error(err);
            console.error('ERROR=>', err);
          });
      },
    });
  };

  const handleNewUser = () => {
    history.push(`/buildingSite/add`);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    const query = `&page=${current - 1}&sort=${sortQuery}`;

    handleGetUnits(query);
  };

  return (
    <div>
      <NewUser>
        {/* <CheckPermission authority="CREATE_UNITS"> */}
        <CustomButton
          icon={<PlusOutlined />}
          type="primary"
          onClick={handleNewUser}
          text="Ekle"
        />
        {/* </CheckPermission> */}
      </NewUser>
      <Table
        dataSource={units}
        columns={COLUMS}
        onChange={handleTableChange}
        pagination={paginationProperties}
      />
    </div>
  );
};
export default BuildingSite;
