import { FC, useContext } from 'react';
import moment from 'moment';
import { GlobalContext } from '../../../context/global.context';
import { downloadFile } from '../../../helpers';
import { excellDownload } from '../../../servives/document/document.service';
import { DownloadExcellIcon } from '../../customIcons';
import { CustomButton } from '../../FormElements';
import { DownloadExcellWrapper } from './DownloadExcell.styled';
interface DownloadExcellProps {
  pathName: string;
}
const DownloadExcell: FC<DownloadExcellProps> = ({
  pathName,
}: DownloadExcellProps) => {
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  const handleDownloadExcell = () => {
    increaseLoading();

    excellDownload(pathName).then((resp) => {
      const doc = {
        uri: `${pathName}-table-${moment().format('DD-MM-YYYY')}`,
      };
      downloadFile(resp, doc);
      decreaseLoading();
    });
  };

  return (
    <DownloadExcellWrapper>
      <CustomButton
        icon={<DownloadExcellIcon />}
        type="primary"
        onClick={handleDownloadExcell}
        text="Excel İndir"
      />
    </DownloadExcellWrapper>
  );
};

export default DownloadExcell;
