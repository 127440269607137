import { authHeader, request } from '../../helpers';
import { ResponseModel, List } from '../models/global.model';
import {
  VehicleModel,
  CreateVehicleModel,
  UpdateVehicleModel,
  CreateVehicleUsageModel,
  VehicleUsageModel,
  UpdateVehicleUsageModel,
} from './vehicle.model';

interface VehicleListModel extends List {
  list: VehicleModel[];
}
interface VehicleUsageListModel extends List {
  list: VehicleUsageModel[];
}

interface VehicleGetByIdResponseModel extends ResponseModel {
  data: VehicleModel;
}
interface VehicleResponseModel extends ResponseModel {
  data: VehicleListModel;
}
interface VehicleCreateResponseModel extends ResponseModel {
  data: VehicleModel;
}
interface VehicleUsageResponseModel extends ResponseModel {
  data: VehicleUsageListModel;
}

function getAll(query?: string): Promise<VehicleResponseModel> {
  return request({
    url: `/vehicles?${query ? query : ''}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function getById(id: string): Promise<VehicleGetByIdResponseModel> {
  return request({
    url: `/vehicles/${id}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function vehicleCreate(
  params: CreateVehicleModel,
): Promise<VehicleCreateResponseModel> {
  return request({
    url: `/vehicles`,
    method: 'POST',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
}

function vehicleUpdate(
  params: UpdateVehicleModel,
): Promise<VehicleResponseModel> {
  return request({
    url: `/vehicles/${params.id}`,
    method: 'PUT',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
}

function vehicleDelete(id: number): Promise<VehicleResponseModel> {
  return request({
    url: `/vehicles/${id}`,
    method: 'DELETE',
    headers: authHeader(),
  });
}

function vehicleUsageCreate(
  params: CreateVehicleUsageModel,
): Promise<VehicleResponseModel> {
  console.log(params, 'params');
  return request({
    url: `/usages`,
    method: 'POST',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
}

function vehicleUsage(id: number): Promise<VehicleUsageResponseModel> {
  return request({
    url: `/usages?vehicle=${id}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function vehicleUpdateUsage(data: UpdateVehicleUsageModel): Promise<any> {
  console.log(data, 'vehicleUpdateUsage');
  return request({
    url: `/usages/${data.id}`,
    method: 'PUT',
    headers: authHeader(),
    data,
  });
}
function vehicleDeleteUsage(id: number): Promise<any> {
  return request({
    url: `/usages/${id}`,
    method: 'DELETE',
    headers: authHeader(),
  });
}

export {
  getAll,
  getById,
  vehicleCreate,
  vehicleUpdate,
  vehicleDelete,
  vehicleUsage,
  vehicleUsageCreate,
  vehicleUpdateUsage,
  vehicleDeleteUsage,
};
