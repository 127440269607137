import React, { FC } from 'react';
import { Table, Button, Space, Tooltip } from 'antd';
import styled from 'styled-components';

import { FilePdfOutlined } from '@ant-design/icons';
interface ServiceListModel {
  description: string;
  id?: number;
  quantity: number;
  unitPrice: number;
  totalPrice?: number;
}

interface VehicleServicesTableType {
  serviceList: ServiceListModel[];
  isShowDelete?: boolean;
  handleDeleteServiceItem?: (key: any) => void | undefined;
}

const CustomTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
`;
const VehicleServicesTable: FC<VehicleServicesTableType> = ({
  serviceList,
  isShowDelete = false,
  handleDeleteServiceItem,
}: VehicleServicesTableType) => {
  const COLUMN = [
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Adet',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Birim Fiyatı',
      dataIndex: 'unitPrice',
      key: 'unitPrice',
    },
    {
      title: 'Toplam Tutar',
      render: (record: any) => (
        <>
          <span>{record?.quantity * record?.unitPrice}</span>
        </>
      ),
    },
    {
      title: '',
      render: (record: any) =>
        isShowDelete && (
          <>
            <Space>
              <Tooltip title="Sil">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<FilePdfOutlined />}
                  onClick={() => {
                    handleDeleteServiceItem &&
                      handleDeleteServiceItem(record.key);
                  }}
                />
              </Tooltip>
            </Space>
          </>
        ),
    },
  ];

  const handleTabelData = () => {
    return serviceList?.map((item: any, i: any) => {
      return {
        ...item,
        key: i,
      };
    });
  };

  const handleTotalCount = (serviceList: any) => {
    let total = 0;
    serviceList.forEach((item: any) => {
      total += item.quantity * item?.unitPrice;
    });
    return total;
  };

  return (
    <div>
      <Table
        dataSource={handleTabelData()}
        columns={COLUMN}
        scroll={{ y: 350 }}
        pagination={false}
      />
      <CustomTotal>
        <p>
          Toplam = <strong>{handleTotalCount(serviceList)}</strong>
        </p>
      </CustomTotal>
    </div>
  );
};
export default VehicleServicesTable;
