import { authHeader, request } from '../../helpers';
import { ResponseModel, List } from '../models/global.model';
import { VehilceServicesModel } from './vehicleServices.model';

interface VehicleServicesListModel extends List {
  list: VehilceServicesModel[];
}

interface VehicleServicesResponseModel extends ResponseModel {
  data: VehicleServicesListModel;
}
interface VehicleServicesCreationResponseModel extends ResponseModel {
  data: VehilceServicesModel;
}

function getByVehivleId(
  id: string,
  query?: string,
): Promise<VehicleServicesResponseModel> {
  return request({
    url: `/services?vehicle=${id}${query ? query : ''}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function createService(
  params: any,
): Promise<VehicleServicesCreationResponseModel> {
  return request({
    url: `/services`,
    method: 'POST',
    headers: authHeader(),
    data: params,
  });
}

export { getByVehivleId, createService };
