interface SelectOptionsModel {
  label: string;
  value: number;
}

export default (
  Arr: any[],
  label: string,
  value: string,
): SelectOptionsModel[] => {
  return Arr.map((item) => {
    return {
      label: item[label],
      value: item[value],
    };
  });
};
