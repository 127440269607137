import styled from 'styled-components';
import { Input } from 'antd';

const CustomInput = styled(Input)`
  border-radius: 5px;
  height: 45px;

  .ant-form-item-explain {
    font-size: 9px;
    text-align: left;
    margin-top: 5px;
  }
`;
export default CustomInput;
