import styled from 'styled-components';

export const ErrorPageWrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .notfound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .bg {
      display: inline-block;
      width: 140px;
      height: 140px;
      background-size: cover;
      &.bg-404 {
        background-image: url(https://colorlib.com/etc/404/colorlib-error-404-18/img/emoji.png);
      }
      &.bg-403 {
        background-image: url(https://img.icons8.com/pastel-glyph/2x/lock.png);
      }
      &:before {
        content: '';
        position: absolute;
        width: 140px;
        height: 140px;
        -webkit-transform: scale(2.4);
        -ms-transform: scale(2.4);
        transform: scale(2.4);
        border-radius: 50%;
        background-color: #f2f5f8;
        z-index: -1;
      }
    }
    h1 {
      font-size: 65px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 10px;
      color: #151723;
      text-transform: uppercase;
    }
    h2 {
      font-size: 21px;
      font-weight: 400;
      margin: 0;
      text-transform: uppercase;
      color: #151723;
    }
    p {
      color: #999fa5;
      font-weight: 400;
    }
    .btn-back {
      display: inline-block;
      font-weight: 700;
      border-radius: 40px;
      text-decoration: none;
      color: #388dbc;
    }
  }
`;
