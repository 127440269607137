import { Button } from 'antd';
import styled from 'styled-components';
import logobg from '../../assets/images/login_bg.png';

type LoginWrapperProps = {
  isShowBg?: boolean;
  isShowBgImage?: boolean;
};
export const LoginWrapper = styled.div<LoginWrapperProps>`
position:relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
  ${(props) => props.isShowBg && `background-color: #130257;`}}
  :before {
    content: ' ';
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 0.2;
    background-size: cover;
    background-repeat: no-repeat;
    ${(props) => props.isShowBgImage && `background-image: url(${logobg})`}}
  }
`;

export const StyledCard = styled.div`
  width: 340px;

  button {
    width: 100%;
    border-radius: 7px;
    height: 50px;
  }
  .ant-input-affix-wrapper-focused {
    background-color: #130257 !important;
  }
  .ant-input-affix-wrapper {
    height: 50px;
    border: 1px solid #ffffff !important;
    background-color: #130257 !important;
    border-radius: 7px;

    input {
      width: 100%;
      border-radius: 7px;
      background-color: #130257 !important;
      color: #ffffff;
    }
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 22px;
  overflow: hidden;
  p {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #130257;
    line-height: 23px;
  }
  span {
    font-size: 14px;
    font-weight: 500;

    color: #000000;
  }

  img {
    width: 100%;
    max-width: 200px;
  }
`;

export const LoginSubmitButton = styled(Button)`
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  margin-top: 20px;
`;
