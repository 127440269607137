import styled from 'styled-components';

export const CreateTranfer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const TransferFormButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
