import { Card, Carousel } from 'antd';
import style from 'styled-components';

export const CustomCarouselItem = style.div`
    padding-bottom:40px;
`;

type CustomCardProps = {
  titleColor?: string;
  height?: string;
};

export const CustomCard = style(Card)<CustomCardProps>`
    min-height:${(props) => `${props.height ?? '170px'}`}}

    border-radius: 6px;

    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.28);
    -moz-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.28);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.28);

    .ant-card-head{
      display: flex ;
      align-items: center;
      justify-content:center;

      &-title{
        padding: 0;
        color: ${(props) => `${props.titleColor ?? ''}`}}

      }
    }

    .ant-card-body{
      padding: 16px;
    }
`;

export const CustomCarousel = style(Carousel)`
  height: 90px;

  .slick-prev, .slick-next{
    top:65%;
    z-index: 2;
    color: black !important;
    font-size: 20px;
    height: 30px;
  }

  .slick-prev,
  .slick-prev:hover {
    left:-15px;
  
  }

  .slick-next,
  .slick-next:hover {
    right:-15px;
  } 
`;

export const CarouselContent = style.div`
  color:#000000;
  align-items: center;
  text-align:center;
  font-size:16px;

  span{
    color:#000000;
    font-weight:bold;
  }
`;
