import { authHeader, request } from '../../helpers';
import { RoleDataModel } from './roles.model';
import { List, ResponseModel } from '../models/global.model';

interface RoleModel extends List {
  list: RoleDataModel[];
}
interface RoleGetByIDModel extends ResponseModel {
  data: RoleDataModel;
}

interface RoleResponseModel extends ResponseModel {
  data: RoleModel;
}
interface RoleCreateModel {
  name: string;
  description?: string;
  isLocked: boolean;
  privileges: number[];
}
interface RoleUpdateModel extends RoleCreateModel {
  id: string;
}

function getAll(query?: string): Promise<RoleResponseModel> {
  return request({
    url: `/roles?${query ? query : ''}`,
    method: 'GET',
    headers: authHeader(),
  });
}
function getById(id: string): Promise<RoleGetByIDModel> {
  return request({
    url: `/roles/${id}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function roleCreate(params: RoleCreateModel): Promise<RoleResponseModel> {
  return request({
    url: `/roles`,
    method: 'POST',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
}

function roleUpdate(params: RoleUpdateModel): Promise<RoleResponseModel> {
  return request({
    url: `/roles/${params.id}`,
    method: 'PUT',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
}
function roleDelete(id: number): Promise<RoleResponseModel> {
  return request({
    url: `/roles/${id}`,
    method: 'DELETE',
    headers: authHeader(),
  });
}

export { getAll, getById, roleCreate, roleUpdate, roleDelete };
