import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { authenticationService } from '../servives';
import { config } from './config';

const client = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=utf-8',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: 'brackets' });
  },
});

const request = (options: AxiosRequestConfig): Promise<any> => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    console.error('Request Error');
    let errorMessage = 'Bir hata oluştu';
    const redirectLogin = () => {
      authenticationService.logout();
      window.location.reload(true);
    };
    if (error.response) {
      const { data } = error.response;
      switch (data.status) {
        case 400:
          errorMessage = data.message;
          break;
        case 401:
          errorMessage = 'Girişe yönlendiriliyor.';
          redirectLogin();
          break;
        case 403:
          errorMessage = 'Bu sayfaya erişim yetkiniz bulunmamaktadır.';
          // redirectLogin();
          break;
        case 404:
          errorMessage = 'Sayfa bulunamadı';
          break;
        default:
          errorMessage = data.message || 'Bir hata oluştu';
          break;
      }
    }
    return Promise.reject(errorMessage);
  };

  return client(options).then(onSuccess).catch(onError);
};

export { request };
