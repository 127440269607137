import React, { useEffect, useContext, ComponentType } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { RoleManagmentContext } from '../context/roleManagment.context';
import { parseJwt, getToken } from '../helpers';

// import { authenticationService } from '../servives';
//
import { DefaultLayout } from './Layouts';

const defaultTitle = {
  main: '',
  subTitle: '',
};

interface IProps extends RouteProps {
  component: ComponentType<any>;
  title?: {
    main: string;
    subTitle: string;
  };
}

const PrivateRoute = ({
  component: Comp,
  title = defaultTitle,
  ...rest
}: IProps): any => {
  const { roles, handleSetRoles } = useContext(RoleManagmentContext);

  useEffect(() => {
    // Check Roles on Store
    if (roles.length === 0) {
      const localToken: any = getToken();
      const parsedJWT: any = localToken && parseJwt(localToken);
      localToken && handleSetRoles(parsedJWT.scopes);
    }
  });

  return (
    <Route
      {...rest}
      render={(props) => {
        const localToken = getToken();
        if (!localToken) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        // TODO : ROLESS
        // if (roles && roles.indexOf(currentUser.role) === -1) {
        //   return <Redirect to={{ pathname: '/403' }} />;
        // }
        return (
          <DefaultLayout title={title}>
            <Comp {...props} />
          </DefaultLayout>
        );
      }}
    />
  );
};

export default PrivateRoute;
