import { authHeader, request } from '../../helpers';
import { WarningDataModel } from './warning.model';
import { ResponseModel, List } from '../models/global.model';

interface WarningModel extends List {
  list: WarningDataModel[];
}

interface WarningResponeseModel extends ResponseModel {
  data: WarningModel;
}

function getAll(query?: string): Promise<WarningResponeseModel> {
  return request({
    url: `/warnings?${query ? query : ''}`,
    method: 'GET',
    headers: authHeader(),
  });
}

export { getAll };
