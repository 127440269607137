import React, { FC, useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { VehicleServicesService, DocumentService } from '../../../servives';
import { GlobalContext } from '../../../context/global.context';
import {
  VehilceServicesModel,
  VehilceServicesItemsModel,
} from '../../../servives/vehicle/vehicleServices.model';
import moment from 'moment';
import { paginationPropertiesModel } from '../../../servives/models/global.model';
import { downloadFile } from '../../../helpers';
//
import styled from 'styled-components';
import { Table, Space, Button, Tooltip, Modal, message } from 'antd';
import {
  InfoCircleOutlined,
  PlusOutlined,
  FileOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { VehicleServicesTable } from '../../../components/commons';
import { CustomButton } from '../../../components/FormElements';
//

const NewVehicleService = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;
const DocumentsWrapper = styled.div`
  border-top: 1px solid #c1c1c1;
  padding-top: 20px;
  
  }
`;
const Document = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .header {
    display: flex;
    align-items: center;

    .title {
      display: flex;
      flex-direction: column;

      width: 200px;
      margin: 0;
      padding: 0 15px;
      font-weight: bold;
      font-size: 14px;

      line-height: 15px;
      border-right: 1px solid #c1c1c1;

      .expiryDate {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        font-style: italic;
      }
    }
    .description {
      margin-left: 20px;
    }
  }
  button {
    border-radius: 50px;
    background: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 16px;
    &:hover {
      cursor: pointer;
    }
    span {
      margin-right: 8px;
    }
  }
`;

interface paramTypes {
  id?: string;
}

interface DocsType {
  id: number;
  name: string;
  description: string;
  expiryDate: string;
  type: string;
  uri: string;
}
const SERVICE_PLACE: any = {
  SERVIS: {
    label: 'Servis',
  },
  ATOLYE: {
    label: 'Atolye',
  },
  SANAYI: {
    label: 'Sanayi',
  },
};

const SERVICE_TYPE: any = {
  BAKIM: {
    label: 'Bakım',
  },
  ONARIM: {
    label: 'Onarım',
  },
};

const VehicleService: FC = () => {
  const [vehicleServices, setVehicleServices] = useState<
    VehilceServicesModel[]
  >([]);
  const [vehicleServicesItems, setVehicleServicesItems] = useState<
    VehilceServicesItemsModel[]
  >([]);
  const [vehicleServicesDocuments, setVehicleServicesDocumentes] = useState<
    DocsType[]
  >([]);

  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });
  const [isShowVehicleServices, setIsShowVehicleServices] = useState(false);
  const params = useParams<paramTypes>();
  const history = useHistory();
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  const COLUMN = [
    {
      title: 'Servis Türü',
      dataIndex: 'serviceType',
      key: 'serviceType',
      sorter: true,
      render: (serviceType: string) => (
        <span>{SERVICE_TYPE[serviceType].label}</span>
      ),
    },
    {
      title: 'Servis Yeri',
      dataIndex: 'servicePlace',
      key: 'servicePlace',
      render: (servicePlace: string) => (
        <span>{SERVICE_PLACE[servicePlace].label}</span>
      ),
    },
    {
      title: 'Servis Adresi',
      dataIndex: 'serviceAddress',
      key: 'serviceAddress',
    },
    {
      title: 'Servis Tarihi',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => (
        <span>{moment(date).format('DD.MM.YYYY')}</span>
      ),
    },
    {
      title: '',
      render: (record: VehilceServicesModel) => (
        <>
          <Space>
            <Tooltip title="Servis Detayi">
              <Button
                type="primary"
                shape="circle"
                icon={<InfoCircleOutlined />}
                onClick={() => {
                  handleShowVehicleServiceDetail(record.serviceItems);
                  setVehicleServicesDocumentes(record.documents);
                }}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  const handleVehicleServices = (query?: string) => {
    increaseLoading();
    params.id &&
      VehicleServicesService.getByVehivleId(params.id, query).then((resp) => {
        decreaseLoading();
        const { list, total } = resp.data;
        const services = list.map((i) => {
          return {
            ...i,
            key: i.id,
          };
        });
        setVehicleServices(services);
        setPaginationProperties((currentState) => ({
          ...currentState,
          total: total,
        }));
      });
  };

  useEffect(() => {
    handleVehicleServices();
  }, []);

  const handleShowVehicleServiceDetail = (
    list: VehilceServicesItemsModel[],
  ) => {
    setIsShowVehicleServices(true);
    setVehicleServicesItems(list);
  };

  const handleCloseModal = () => {
    setIsShowVehicleServices(false);
  };

  const handleNewVehicleService = () => {
    history.push(`/vehicle/services/${params.id}/add`);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    const query = `&page=${current - 1}&sort=${sortQuery}`;

    handleVehicleServices(query);
  };

  const handleDownloadFile = (doc: DocsType) => {
    increaseLoading();
    DocumentService.download(doc.id)
      .then((resp) => {
        downloadFile(resp, doc);
        message.success('İşlem Başarılı.');
        decreaseLoading();
      })
      .catch(() => {
        message.error('Bir Hata Oluştu.');
        decreaseLoading();
      });
  };

  return (
    <div>
      <NewVehicleService>
        {/* <CheckPermission authority="CREATE_USERS"> */}
        <CustomButton
          icon={<PlusOutlined />}
          type="primary"
          onClick={handleNewVehicleService}
          text="Servis Ekle"
        />
        {/* </CheckPermission> */}
      </NewVehicleService>
      <Table
        dataSource={vehicleServices}
        columns={COLUMN}
        pagination={paginationProperties}
        onChange={handleTableChange}
      />
      {isShowVehicleServices && (
        <Modal
          title="Servis Detayi"
          visible={isShowVehicleServices}
          onCancel={handleCloseModal}
          width={750}
          footer={null}
        >
          <VehicleServicesTable serviceList={vehicleServicesItems} />
          {vehicleServicesDocuments.length !== 0 && (
            <DocumentsWrapper>
              <h3>
                <strong>Servis Dökümanları</strong>
              </h3>
              {vehicleServicesDocuments.map((doc, i) => (
                <Document key={i}>
                  <div className="header">
                    <FileOutlined
                      style={{ fontSize: '36px', color: '#e20e54' }}
                    />
                    <p className="title">
                      {doc.name}
                      <span className="expiryDate">{doc.expiryDate}</span>
                    </p>
                    <span className="description">{doc.description}</span>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      handleDownloadFile(doc);
                    }}
                  >
                    <DownloadOutlined />
                    İndir
                  </button>
                </Document>
              ))}
            </DocumentsWrapper>
          )}
        </Modal>
      )}
    </div>
  );
};
export default VehicleService;
