import styled from 'styled-components';

export const DownloadExcellWrapper = styled.div`
  button {
    display: flex;
    gap: 5px;
    align-items: center;

    svg {
      path {
        fill: white;
      }
    }
  }
`;
