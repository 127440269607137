import { authHeader, request } from '../../helpers';
import { parseJwt } from '../../helpers';

import {
  UserProps,
  TokenResponse,
  ReserPassword,
  ReserPasswordResponse,
} from './authentication.model';

const login = ({ tckn, password }: UserProps): Promise<any> => {
  return request({
    url: '/login',
    method: 'POST',
    data: {
      tckn,
      password,
    },
  })
    .then(({ token }: TokenResponse) => {
      return new Promise((resolve) => {
        localStorage.setItem('makimsanToken', JSON.stringify({ token: token }));
        const parsedJwt = parseJwt(token);
        resolve(parsedJwt);
      });
    })
    .catch((err) => {
      return new Promise((resolve, rejects) => {
        rejects(err);
      });
    });
};

const logout = (): any => {
  localStorage.removeItem('makimsanToken');
};

const resetPassword = (
  params: ReserPassword,
): Promise<ReserPasswordResponse> => {
  return request({
    url: `/users/${params.id}/change-pass`,
    method: 'POST',
    headers: authHeader(),
    data: {
      ...params,
    },
  });
};

const authenticationService = {
  login,
  logout,
  resetPassword,
};

export { authenticationService };
