export interface ParsedJwtModel {
  exp: number;
  iat: number;
  roles: string;
  scopes: string;
  sub: string;
  units: string;
  name: string;
  surname: string;
  userId: number;
  unitIds: string;
}

const parseJwt = (token: string): ParsedJwtModel => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export { parseJwt };
