import React, { FC, useState, createContext } from 'react';

export type RoleManagmentType = {
  roles: string[];
  handleSetRoles: (scope: string) => void;
};

export const RoleManagmentContext = createContext<RoleManagmentType>({
  roles: [],
  handleSetRoles: () => {
    return;
  },
});

const RoleProvider: FC = ({ children }: any) => {
  const [roles, setRoles] = useState<string[]>([]);

  const handleSetRoles = (scope: string): void => {
    const Arr = scope.split(',');
    setRoles(Arr);
  };

  return (
    <RoleManagmentContext.Provider value={{ roles, handleSetRoles }}>
      {children}
    </RoleManagmentContext.Provider>
  );
};

export default RoleProvider;
