import React, { FC, useState, useEffect, useContext } from 'react';
import { UnitsComsumption } from '../../servives/graph/graph.service';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { DatePicker } from 'antd';
import {
  CustomLegendWrapper,
  CustomTootipWrapper,
  RangePickerWrapper,
} from './Graph.styled';
import moment from 'moment';
import { GlobalContext } from '../../context/global.context';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

interface BarChartModel {
  name: string;
  uv: number;
}

const Colors = ['#216477', '#82ca9d', '#ffc658'];

const UnitsConsumptionGraph: FC = () => {
  const today = new Date();
  const yesterday = new Date(today.setDate(today.getDate() - 1));

  const [barCharData, setBarChartData] = useState<BarChartModel[]>([]);
  const [rangePickerValue, setRangePickerValue] = useState<any>([
    moment(yesterday, 'DD-MM-YYYY'),
    moment(yesterday, 'DD-MM-YYYY'),
  ]);
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    increaseLoading();
    UnitsComsumption(rangePickerValue)
      .then((resp) => {
        decreaseLoading();
        const data = resp.data.map((item) => {
          const comsumption: any = {
            name: item.unit,
            totalMazot: item.totalMazot,
          };

          return comsumption;
        });

        setBarChartData(data);
      })
      .catch((err) => {
        decreaseLoading();
        console.error('ERROR=>', err);
      });
  }, [rangePickerValue]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <CustomTootipWrapper>
          <p className="label">{label}</p>
          {payload.map((item: any, idx: number) => (
            <p
              key={`${item.name}-${idx}`}
              className="label"
              style={{ color: `${Colors[idx]}` }}
            >{`${handleVehicleText(item.name)} : ${item.value}`}</p>
          ))}
        </CustomTootipWrapper>
      );
    }

    return null;
  };

  const handleVehicleText = (type: string) => {
    if (type === 'totalMazot') {
      return 'Kullanım Bilgisi (Litre)';
    }
  };

  const CustomLegend = ({ payload }: any) => {
    return (
      <CustomLegendWrapper>
        {payload?.map((entry: any, index: any) => (
          <div className="customLegend" key={`item-${index}`}>
            <div
              className="square"
              style={{ backgroundColor: `${Colors[index]}` }}
            />
            {handleVehicleText(entry.value)}
          </div>
        ))}
      </CustomLegendWrapper>
    );
  };

  const handleOnChange = (e: any) => {
    setRangePickerValue(e);
  };

  return (
    <>
      <RangePickerWrapper>
        <RangePicker
          defaultValue={rangePickerValue}
          format="DD-MM-YYYY"
          locale={locale}
          size={'small'}
          onChange={handleOnChange}
          disabledDate={(current) => {
            return current && current.valueOf() > Date.now();
          }}
        />
      </RangePickerWrapper>
      <ResponsiveContainer width="90%" height={400}>
        <BarChart
          data={barCharData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} />
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          <Bar dataKey="totalMazot" fill="#216477" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default UnitsConsumptionGraph;
