import React, { FC, useContext, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Privileges,
  RolesService,
  authenticationService,
} from '../../servives';
import { GlobalContext } from '../../context/global.context';
//
import { Transfer, Form, Row, Col, message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { CustomInput, CustomButton } from '../../components/FormElements';
import styled from 'styled-components';

const CustomRolesFormActionWrapperr = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
const CustomTransferWrapper = styled.div`
  .ant-transfer-list {
    border-radius: 10px;
    overflow: hidden;
    .ant-transfer-list-body {
      background: #ffffff;
    }
  }
`;

interface MockDataTypes {
  key: string;
  id: number;
  title: string;
  description: string;
}
interface paramTypes {
  id?: string;
}
interface RoleTypes {
  name: string;
  description?: string;
}

const RoleForm: FC = () => {
  const [dataSource, setdataSource] = useState<MockDataTypes[]>([]);
  const [targetKeys, settargetKeys] = useState<string[]>();
  const [form] = Form.useForm();
  const params = useParams<paramTypes>();
  const histroy = useHistory();
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  useEffect(() => {
    increaseLoading();
    Privileges.getAll()
      .then((privileges) => {
        decreaseLoading();
        const customPrivileges: MockDataTypes[] = privileges.data.list.map(
          (item) => {
            return {
              id: item.id,
              key: item.privilegeId,
              description: item.description,
              title: item.name,
            };
          },
        );
        setdataSource(customPrivileges);
      })
      .catch((err) => {
        decreaseLoading();
        console.error('ERROR=>', err);
      });
  }, []);

  useEffect(() => {
    if (params?.id) {
      increaseLoading();
      RolesService.getById(params.id)
        .then((resp) => {
          decreaseLoading();
          const { name, description, privileges } = resp.data;
          form.setFieldsValue({
            name,
            description,
          });
          const newPrivileges = privileges
            .sort((a, b) => {
              return a.id - b.id;
            })
            .map((item) => item.privilegeId);
          settargetKeys(newPrivileges);
        })
        .catch((err) => {
          decreaseLoading();
          console.error('ERROR=>', err);
        });
    }
  }, []);

  const handleChange = (targetKeys: string[]) => {
    settargetKeys(targetKeys);
  };

  const renderItem = (item: MockDataTypes) => {
    const customLabel = (
      <span className="custom-item">
        {item.title} - {item.description}
      </span>
    );
    return {
      label: customLabel, // for displayed item
      value: item.title, // for title and filter matching
    };
  };

  const handleUpdateRole = (value: RoleTypes) => {
    increaseLoading();
    const keys: any = targetKeys?.map((keys) => {
      return dataSource.find((item) => item.key === keys);
    });

    if (params?.id) {
      RolesService.roleUpdate({
        id: params.id,
        ...value,
        isLocked: false,
        privileges: keys.filter(Boolean)?.map((item: any) => item?.id),
      })
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          authenticationService.logout();
          histroy.push('/login');
        })
        .catch((err) => {
          decreaseLoading();
          message.error(err);
          console.error('ERROR=>', err);
        });
    }
  };

  const handleSubmit = (value: RoleTypes) => {
    const keys: any = targetKeys?.map((keys) => {
      return dataSource.find((item) => item.key === keys);
    });
    if (params?.id) {
      Modal.confirm({
        title: 'Uyari!',
        icon: <ExclamationCircleOutlined />,
        content:
          'İşleme Devam Ettikten Sonra Login Saygafına Yönlendirileceksiniz',
        okText: 'Cikis yap',
        cancelText: 'Iptal',
        onOk: () => {
          handleUpdateRole(value);
        },
      });
      // Edit
    } else {
      // Add
      increaseLoading();

      RolesService.roleCreate({
        ...value,
        isLocked: false,
        privileges: keys?.map((item: any) => item?.id),
      })
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          histroy.push('/roles');
        })
        .catch((err) => {
          decreaseLoading();
          message.error(err);
          console.error('ERROR=>', err);
        });
    }
  };

  return (
    <div>
      <Form name="user" layout="vertical" form={form} onFinish={handleSubmit}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="name"
              label="Rol Adı"
              rules={[{ required: true, message: 'Lütfen Rol Adı Giriniz!' }]}
            >
              <CustomInput type="text" placeholder="Rol Adı" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="description" label="Rol Açıklaması">
              <CustomInput type="text" placeholder="Rol Açıklaması" />
            </Form.Item>
          </Col>
        </Row>
        <CustomTransferWrapper>
          <Transfer
            dataSource={dataSource}
            titles={['Yetkiler', 'Sahip olunan Yetkiler']}
            listStyle={{
              width: '50%',
              height: 400,
            }}
            showSelectAll={false}
            targetKeys={targetKeys}
            onChange={handleChange}
            render={renderItem}
            locale={{
              itemUnit: ' ',
              itemsUnit: ' ',
              notFoundContent: 'Yetki Bulunamadi',
            }}
          />
        </CustomTransferWrapper>
        <CustomRolesFormActionWrapperr>
          <CustomButton type="primary" htmlType="submit">
            Kaydet
          </CustomButton>
        </CustomRolesFormActionWrapperr>
      </Form>
    </div>
  );
};
export default RoleForm;
