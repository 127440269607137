import styled from 'styled-components';

export const CustomLegendWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .customLegend {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    .square {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
`;

export const CustomTootipWrapper = styled.div`
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 3px;
`;

export const RangePickerWrapper = styled.div`
  display: flex;
  justify-content: end;
`;
