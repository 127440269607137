import React, { FC, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { GlobalContext } from '../../context/global.context';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';

import {
  VehicleService,
  TransferService,
  DocumentService,
} from '../../servives';
import { VehicleModel } from '../../servives/vehicle/vehicle.model';
import createSelectOptions from '../../utils/createSelectOptions';
//
import { Form, Row, Col, message } from 'antd';
import {
  CustomInput,
  CustomButton,
  CustomSelect,
  CustomDatePicker,
  CustomUpload,
} from '../../components/FormElements';
import { TransferFormButtonWrapper } from './Transfer.styled';

interface SelectOptionsModel {
  label: string;
  value: number;
}

const fuelLevelOptions = [
  {
    label: '100%',
    value: 100,
  },
  {
    label: '90%',
    value: 90,
  },
  {
    label: '80%',
    value: 80,
  },
  {
    label: '70%',
    value: 70,
  },
  {
    label: '60%',
    value: 60,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '40%',
    value: 40,
  },
  {
    label: '30%',
    value: 30,
  },
  {
    label: '20%',
    value: 20,
  },
  {
    label: '10%',
    value: 10,
  },
];

const TransferForm: FC = () => {
  const [vehicleOptionsData, setOptionsVehicleData] = useState<
    SelectOptionsModel[]
  >([]);
  const [vehicleData, setVechileData] = useState<VehicleModel[]>([]);
  const [
    selectedVehicleData,
    setSelectedVehicleeData,
  ] = useState<VehicleModel>();
  const [doc, setDoc] = useState<any>([]);

  const {
    increaseLoading,
    decreaseLoading,
    localToken,
    selectedUnitId,
    units,
  } = useContext(GlobalContext);

  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    form.setFieldsValue({ from: selectedUnitId });
    selectedUnitId !== 0 && getVehicleAll();
  }, [selectedUnitId]);

  const getVehicleAll = () => {
    const query = `unit=${selectedUnitId}&size=3000`;
    increaseLoading();
    VehicleService.getAll(query).then((resp) => {
      decreaseLoading();
      const { list } = resp.data;
      const vehicle = list.map((item) => {
        return {
          label:
            item.plate != null
              ? `${item.vehicleId} - ${item.kind} - ${item.plate}`
              : `${item.vehicleId} - ${item.kind}`,
          value: item.id,
        };
      });
      setOptionsVehicleData(vehicle);
      setVechileData(list);
    });
  };

  const onFinish = (values: any) => {
    increaseLoading();
    const { userId } = localToken;
    TransferService.createTransfer({
      ...values,
      createdBy: userId,
    })
      .then((resp) => {
        const { id } = resp.data;

        if (doc.length === 0) {
          decreaseLoading();
          message.success(resp.message);
          history.push('/transfer');
        } else {
          DocumentService.multipleUpload({ ...doc, id }, 'transfers')
            .then((docResp) => {
              decreaseLoading();
              message.success(docResp.message);
              history.push('/transfer');
            })
            .catch((err) => {
              console.error('ERROR=>', err);
              decreaseLoading();
              message.error(err);
            });
        }
      })
      .catch((err) => {
        console.error('ERROR=>', err);
        decreaseLoading();
        message.error(err);
      });
  };

  const handleDocUpload = (fileList: any) => {
    setDoc(fileList);
  };

  const handleSetSelectedVehicle = (id: any) => {
    const vehicle = vehicleData.find((vehicle) => vehicle.id === id);
    setSelectedVehicleeData(vehicle);
  };

  return (
    <>
      <Form name="user" onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item name="from" label="Gönderen Şantiye">
              <CustomSelect
                placeholder="Lütfen Şantiye Seçiniz!"
                options={createSelectOptions(units, 'name', 'id')}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="to"
              label="Gönderilen Şantiye"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Gönderilen Şantiye Seçiniz!',
                },
              ]}
            >
              <CustomSelect
                placeholder="Lütfen Şantiye Seçiniz!"
                options={createSelectOptions(units, 'name', 'id')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vehicle"
              label="Araç"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Araç Seçiniz!',
                },
              ]}
            >
              <CustomSelect
                placeholder="Lütfen Araç Seçiniz!"
                options={vehicleOptionsData}
                onChange={(id) => handleSetSelectedVehicle(id)}
                showSearch
                filterOption={(input, option) => {
                  const filter =
                    option &&
                    option.label &&
                    option.label
                      .toString()
                      .toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0;

                  return !!filter;
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="transferDate"
              label="Sevk Tarih Seçiniz."
              rules={[
                {
                  required: true,
                  message: 'Lütfen Sevk Tarih Seçiniz.',
                },
              ]}
            >
              <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
          {selectedVehicleData && (
            <Col span={12}>
              <Form.Item
                name="lastUsage"
                label={`Sevk Çıkışı ${
                  selectedVehicleData && selectedVehicleData.kmUsage
                    ? "Km'si"
                    : "Saat'i"
                }`}
                rules={[
                  {
                    required: false,
                    message: `Lütfen Sevk Çıkışı ${
                      selectedVehicleData && selectedVehicleData.kmUsage
                        ? "Km'si"
                        : "Saat'i"
                    } Giriniz!,`,
                  },
                ]}
              >
                <CustomInput
                  type="number"
                  placeholder={`Kaç ${
                    selectedVehicleData && selectedVehicleData.kmUsage
                      ? 'Kmde'
                      : 'Saatte'
                  }`}
                />
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item
              name="fuelLevel"
              label="Yakıt Seviyesi"
              rules={[
                { required: true, message: 'Lütfen Yakıt Seviyesi Seçiniz.' },
              ]}
            >
              <CustomSelect
                placeholder="Yakıt Seviyesi"
                options={fuelLevelOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="Açıklama">
              <CustomInput type="text" placeholder="Açıklama" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="docFile" label="Fotoğraflar">
              <CustomUpload
                isDocDisable={false}
                DocUpload={handleDocUpload}
                multiple
                accept=".png, .jpg, .jpeg"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <TransferFormButtonWrapper>
                <CustomButton type="primary" htmlType="submit">
                  Kaydet
                </CustomButton>
              </TransferFormButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default TransferForm;
