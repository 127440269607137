import { authHeader, request } from '../../helpers';
import { PrivilegeDataModel } from './privileges.model';
import { ResponseModel, List } from '../models/global.model';

interface PrivilegeModel extends List {
  list: PrivilegeDataModel[];
}

interface PrivilegeResponeseModel extends ResponseModel {
  data: PrivilegeModel;
}

function getAll(): Promise<PrivilegeResponeseModel> {
  return request({
    url: `/privileges?paged=false&sort=id`,
    method: 'GET',
    headers: authHeader(),
  });
}

export { getAll };
