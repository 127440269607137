import React, { useState, useEffect, FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckPermission } from '../../../HOC';

import { VehicleKindService } from '../../../servives';
import { GlobalContext } from '../../../context/global.context';
import { paginationPropertiesModel } from '../../../servives/models/global.model';

//
import styled from 'styled-components';
import { Table, Button, Tooltip, Space, message } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { CustomButton } from '../../../components/FormElements';

const NewUser = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

interface VehicleTypeData {
  key: number;
  id: number;
  name: string;
  type: string;
}

const VehicleType: FC = () => {
  const [vehicleTypeData, setVehicleTypeData] = useState<VehicleTypeData[]>([]);
  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  const history = useHistory();

  const vehicleTypeEnums: any = {
    VEHICLE: {
      label: 'Araç',
    },
    EQUIPMENT: {
      label: 'İş Makinesi',
    },
  };
  const COLUMS = [
    {
      title: 'Arac Cinsi',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Arac Türü',
      dataIndex: 'type',
      key: 'type',
      sorter: true,

      render: (type: string) => <span>{vehicleTypeEnums[type].label}</span>,
    },

    {
      title: 'Actions',
      render: (record: VehicleTypeData) => (
        <>
          <Space>
            <CheckPermission authority="UPDATE_VEHICLE_KINDS">
              <Tooltip title="Edit">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleRoleEdit(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="DELETE_VEHICLE_KINDS">
              <Tooltip title="Delete">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleVehicleTypeDelete(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    getVehicleKindAll();
  }, []);

  const getVehicleKindAll = (query?: string) => {
    increaseLoading();
    VehicleKindService.getAll('', query).then((resp) => {
      decreaseLoading();
      const { total, list } = resp.data;
      const types = list.map((item) => {
        return { key: item.id, id: item.id, name: item.name, type: item.type };
      });
      setVehicleTypeData(types);
      setPaginationProperties((currentState) => ({
        ...currentState,
        total: total,
      }));
    });
  };

  const handleRoleEdit = (id: number) => {
    history.push(`/vehicle-kind/edit/${id}`);
  };

  const handleVehicleTypeDelete = (id: number) => {
    increaseLoading();
    VehicleKindService.deleteVehicleKind(id)
      .then(() => {
        message.success('İşlem Başarılı');
        getVehicleKindAll();
        decreaseLoading();
      })
      .catch((err) => {
        message.error(err);
        decreaseLoading();
        console.error('ERROR=>', err);
      });
  };
  const handleAdd = () => {
    history.push(`/vehicle-kind/add`);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    const query = `&page=${current - 1}&sort=${sortQuery}`;

    getVehicleKindAll(query);
  };

  return (
    <div>
      <NewUser>
        <CheckPermission authority="CREATE_VEHICLE_KINDS">
          <CustomButton
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleAdd}
            text="Ekle"
          />
        </CheckPermission>
      </NewUser>

      <Table
        dataSource={vehicleTypeData}
        columns={COLUMS}
        pagination={paginationProperties}
        onChange={handleTableChange}
      />
    </div>
  );
};
export default VehicleType;
