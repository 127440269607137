export const transferStates: any = {
  APPROVED: {
    label: 'Onaylandi',
  },
  CANCELLED: {
    label: 'İptal edildi',
  },
  REGISTERED: {
    label: 'Kayıt yapıldı',
  },
};
