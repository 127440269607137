import React, { FC, useEffect, useContext, useState, ReactNode } from 'react';
import { RoleManagmentContext } from '../context/roleManagment.context';

interface CheckPermission {
  authority: string;
  children: ReactNode;
}

const CheckPermission: FC<CheckPermission> = ({
  authority,
  children,
}: CheckPermission) => {
  const [isPermission, setIsPermission] = useState<boolean>(false);
  const { roles } = useContext(RoleManagmentContext);

  useEffect(() => {
    roles.includes(authority) && setIsPermission(true);
  });

  return <div>{isPermission && children}</div>;
};
export default CheckPermission;
