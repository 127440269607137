import React, { useState, useEffect, useContext, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { RolesService } from '../../servives';
import { GlobalContext } from '../../context/global.context';
import { CheckPermission } from '../../HOC';
import { paginationPropertiesModel } from '../../servives/models/global.model';

//
import styled from 'styled-components';
import { Table, Button, Tooltip, Space, Modal, message } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { CustomButton } from '../../components/FormElements';

const NewUser = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

interface RoleData {
  id: number;
  locked: boolean;
  name: string;
  description: string;
  roleId: string;
}

const Roles: FC = () => {
  const [roles, setRoles] = useState<RoleData[]>([]);
  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });
  const { increaseLoading, decreaseLoading } = useContext(GlobalContext);

  const history = useHistory();

  const COLUMS = [
    {
      title: 'Rol Adı',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Açıklama',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      render: (record: RoleData) => (
        <>
          <Space>
            <CheckPermission authority="UPDATE_ROLES">
              <Tooltip title="Düzenle">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleRoleEdit(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="DELETE_ROLES">
              <Tooltip title="Sil">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleRoleDelete(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
          </Space>
        </>
      ),
    },
  ];

  const getRolesAll = (query?: string) => {
    increaseLoading();
    RolesService.getAll(query).then((resp) => {
      decreaseLoading();
      const { total, list } = resp.data;
      const roleTableData = list.map((item) => {
        return {
          key: item.id,
          ...item,
        };
      });
      setRoles(roleTableData);
      setPaginationProperties((currentState) => ({
        ...currentState,
        total: total,
      }));
    });
  };

  useEffect(() => {
    getRolesAll();
  }, []);

  const handleRoleEdit = (id: number) => {
    history.push(`/roles/edit/${id}`);
  };

  const handleRoleDelete = (id: number) => {
    Modal.confirm({
      title: 'Uyari!',
      icon: <ExclamationCircleOutlined />,
      content: 'Rolu Silmek istedeğinize emin misiniz?',
      okText: 'Sil',
      cancelText: 'Iptal',
      onOk: () => {
        RolesService.roleDelete(id)
          .then(() => {
            getRolesAll();
            message.success('İşlem Başarılı.');
          })
          .catch((err) => {
            console.error('Error =>', err);
            message.error(err);
          });
      },
    });
  };

  const handleNewUser = () => {
    history.push(`/roles/add`);
  };
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    const query = `&page=${current - 1}&sort=${sortQuery}`;

    getRolesAll(query);
  };

  return (
    <div>
      <NewUser>
        <CheckPermission authority="CREATE_ROLES">
          <CustomButton
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleNewUser}
            text="Ekle"
          />
        </CheckPermission>
      </NewUser>
      <Table
        dataSource={roles}
        columns={COLUMS}
        pagination={paginationProperties}
        onChange={handleTableChange}
      />
    </div>
  );
};
export default Roles;
