import { authHeader, request } from '../../helpers';
import { UnitModel, UnitCreateModel, UnitUpdateModel } from './unit.model';

import { ResponseModel, List } from '../models/global.model';

interface UnitListModel extends List {
  list: UnitModel[];
}

interface UnitGetByIdModel extends ResponseModel {
  data: UnitModel;
}
interface UnitResponseModel extends ResponseModel {
  data: UnitListModel;
}

function getAll(query?: string): Promise<UnitResponseModel> {
  return request({
    url: `/units?${query ? query : ''}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function getById(id: string): Promise<UnitGetByIdModel> {
  return request({
    url: `/units/${id}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function UnitCreate(params: UnitCreateModel): Promise<UnitGetByIdModel> {
  return request({
    url: `/units`,
    method: 'POST',
    headers: authHeader(),
    data: { ...params },
  });
}

function UnitUpdate(params: UnitUpdateModel): Promise<UnitGetByIdModel> {
  return request({
    url: `/units/${params.id}`,
    method: 'PUT',
    headers: authHeader(),
    data: { ...params },
  });
}

function UnitDelete(id: number): Promise<UnitGetByIdModel> {
  return request({
    url: `/units/${id}`,
    method: 'DELETE',
    headers: authHeader(),
  });
}

export { getAll, getById, UnitCreate, UnitUpdate, UnitDelete };
