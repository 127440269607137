import React, { useState, useEffect, useContext, FC } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckPermission } from '../../HOC';
import { User, authenticationService } from '../../servives';
import { GlobalContext } from '../../context/global.context';
import { paginationPropertiesModel } from '../../servives/models/global.model';

//
import styled from 'styled-components';
import { Table, Tag, Button, Tooltip, Space, message } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  BlockOutlined,
} from '@ant-design/icons';
import { CustomButton } from '../../components/FormElements';
import { UserReadDataModel } from '../../servives/user/user.model';
import { RoleDataModel } from '../../servives/roles/roles.model';
import { UnitModel } from '../../servives/unit/unit.model';
import { DownloadExcell } from '../../components/commons';

const NewUser = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 10px;
`;
interface UserDataTableData extends UserReadDataModel {
  key: number;
}

// TODO: Table reset Password Buttonu gelicek

const Users: FC = () => {
  const [usersTableData, setUsersTableData] = useState<UserDataTableData[]>([]);
  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });

  const history = useHistory();

  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  const COLUMS = [
    {
      title: 'İsim',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Soy İsim',
      dataIndex: 'surname',
      key: 'surname',
      sorter: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Şantiye',
      dataIndex: 'units',
      key: 'units',
      sorter: true,
      render: (record: UnitModel[]) => {
        return record.map((unit) => (
          <Tag color={'green'} key={unit.id}>
            {unit.name}
          </Tag>
        ));
      },
    },
    {
      title: 'Rol',
      key: 'roles',
      dataIndex: 'roles',
      render: (Roles: RoleDataModel[]) => (
        <>
          {Roles?.map((role, i) => {
            const color = ['red', 'gold', 'yellow', 'geekblue', 'green'];

            return (
              <Tag color={color[i]} key={role.roleId}>
                {role.name.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: '',
      render: (record: UserDataTableData) => (
        <>
          <Space>
            <CheckPermission authority="UPDATE_USERS">
              <Tooltip title="Düzenle">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleUserEdit(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="DELETE_USERS">
              <Tooltip title="Sil">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleUserDelete(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="CREATE_ROLES">
              <Tooltip title="Şifre Yenile">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<BlockOutlined />}
                  onClick={() => {
                    handleReserPassword(record);
                  }}
                />
              </Tooltip>
            </CheckPermission>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    getUserALL();
  }, []);

  const getUserALL = (query?: string) => {
    increaseLoading();
    User.getAll(query).then((resp) => {
      decreaseLoading();
      const { list, total } = resp.data;

      const userTableData: UserDataTableData[] = list.map((user) => {
        return {
          ...user,
          key: user.id,
        };
      });
      setUsersTableData(userTableData);
      setPaginationProperties((currentState) => ({
        ...currentState,
        total: total,
      }));
    });
  };

  const handleUserEdit = (id: number) => {
    history.push(`/users/edit/${id}`);
  };

  const handleUserDelete = (id: number) => {
    increaseLoading();
    User.userDelete(id).then(() => {
      getUserALL();
      decreaseLoading();
    });
  };

  const handleReserPassword = (record: any) => {
    increaseLoading();
    authenticationService
      .resetPassword({
        id: record.id,
        newPassword: record.tckn,
      })
      .then(() => {
        decreaseLoading();
        message.success('Şifre Tc Kimlik numarası olarak Sıfırlandı.');
      });
  };

  const handleNewUser = () => {
    history.push(`/users/add`);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    const query = `&page=${current - 1}&sort=${sortQuery}`;

    getUserALL(query);
  };
  const excellApiUrl = history.location.pathname.substring(1);

  return (
    <div>
      <NewUser>
        <DownloadExcell pathName={excellApiUrl} />
        <CheckPermission authority="CREATE_USERS">
          <CustomButton
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleNewUser}
            text="Ekle"
          />
        </CheckPermission>
      </NewUser>

      <Table
        dataSource={usersTableData}
        columns={COLUMS}
        key="id"
        pagination={paginationProperties}
        onChange={handleTableChange}
      />
    </div>
  );
};
export default Users;
