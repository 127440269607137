import React, { FC } from 'react';

const DownloadExcellIcon: FC = () => {
  return (
    <svg
      className="DownloadExcell"
      width="18px"
      height="18px"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <path
          d="M371.514,192h-67.646l-47.865,70.516L208.131,192h-67.644l81.081,121.232L130.081,448h131.12v-45.788h-30.278
       l25.078-37.497L311.699,448h70.221l-91.488-134.768L371.514,192z M451.717,99.715l-71.434-71.431C364.729,12.728,334,0,312,0H72
       C50,0,32,18,32,40v432c0,22,18,40,40,40h368c22,0,40-18,40-40V168C480,146,467.271,115.271,451.717,99.715z M429.09,122.343
       c1.564,1.565,3.125,3.487,4.639,5.657H352V46.272c2.17,1.515,4.092,3.075,5.656,4.64L429.09,122.343z M448,472c0,4.336-3.664,8-8,8
       H72c-4.336,0-8-3.664-8-8V40c0-4.336,3.664-8,8-8h240c2.416,0,5.127,0.305,8,0.852V160h127.148c0.547,2.873,0.852,5.583,0.852,8
       V472z"
        />
      </g>
    </svg>
  );
};

export default DownloadExcellIcon;
