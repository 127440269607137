import styled from 'styled-components';
import { Select } from 'antd';

const CustomSelect = styled(Select)`
  && {
    height: auto;

    .ant-select-selector {
      min-height: 45px;
      border-radius: 5px;
      display: flex;
      align-items: center;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
    }

    .ant-select-selection-search {
      display: flex;
      align-items: center;
    }
  }
`;

export default CustomSelect;
