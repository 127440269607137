import React, { FC, useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/global.context';
import { User, RolesService } from '../../servives';
import { UserCreateModel } from '../../servives/user/user.model';
import { paramTypes } from '../../servives/models/global.model';
//
import { Row, Col, Form, message } from 'antd';

import styled from 'styled-components';
import {
  CustomInput,
  CustomSelect,
  CustomButton,
} from '../../components/FormElements';
import createSelectOptions from '../../utils/createSelectOptions';

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface SelectOptionsModel {
  label: string;
  value: number;
}

const UserForm: FC = () => {
  const [role, setRole] = useState<SelectOptionsModel[]>([]);
  const params = useParams<paramTypes>();
  const history = useHistory();
  const [form] = Form.useForm();
  const { decreaseLoading, increaseLoading, units } = useContext(GlobalContext);

  useEffect(() => {
    increaseLoading();
    RolesService.getAll()
      .then((resp) => {
        decreaseLoading();
        const { list } = resp.data;
        const role: SelectOptionsModel[] = list.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setRole(role);
      })
      .catch((err) => {
        console.error('ERROR=>', err);
        decreaseLoading();
      });
  }, []);

  useEffect(() => {
    if (params?.id) {
      increaseLoading();
      // Edit
      User.getById(params.id).then((resp) => {
        const { email, gsm, name, surname, roles, tckn, units } = resp.data;

        form.setFieldsValue({
          tckn,
          name,
          surname,
          email,
          gsm,
          units: units.map((u) => u.id),
          roles: roles.map((role) => role.id),
        });
      });
      decreaseLoading();
    }
  }, []);

  const onFinish = (values: UserCreateModel) => {
    increaseLoading();

    if (params?.id) {
      // Edit
      User.userUpdate({
        id: +params.id,
        ...values,
      })
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          history.push('/users');
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    } else {
      // Create
      User.userCreate({ ...values })
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          history.push('/users');
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    }
  };

  return (
    <div>
      <Form name="user" onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="İsim"
              rules={[
                { required: true, message: 'Lütfen Kullanıcı Adı Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Kullanıcı Adı" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tckn"
              label="Tc"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Kullanıcı Tc Numarası Giriniz!',
                },
              ]}
            >
              <CustomInput type="text" placeholder="Tc Numarası" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="surname"
              label="Soy İsim"
              rules={[
                {
                  required: true,
                  message: 'Lütfen Kullanıcı Soy Adı Giriniz!',
                },
              ]}
            >
              <CustomInput type="text" placeholder="Kullanıcı Soy Adı" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'Lütfen Email Giriniz!' }]}
            >
              <CustomInput type="text" placeholder="Email" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="gsm" label="Telefon Numarası">
              <CustomInput type="text" placeholder="Telefon Numarası" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="units" label="Şantiye">
              <CustomSelect
                placeholder="Lütfen Şantiye Seçiniz!"
                mode="multiple"
                options={createSelectOptions(units, 'name', 'id')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="roles" label="Role">
              <CustomSelect
                mode="multiple"
                placeholder="Lütfen Rol Seçiniz"
                options={role}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <ButtonWrapper>
                <CustomButton type="primary" htmlType="submit">
                  Kaydet
                </CustomButton>
              </ButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default UserForm;
