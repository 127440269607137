import styled from 'styled-components';
import { DatePicker } from 'antd';

const CustomDatePicker = styled(DatePicker)`
  height: 45px !important;
  border-radius: 5px !important;
  display: flex;
  align-items: center;
`;

export default CustomDatePicker;
