export const DocumantTypeOptionsEnums = [
  {
    value: 'INSURANCE',
    label: 'Sigorta Belgesi',
  },
  {
    value: 'KASKO',
    label: 'Kasko Belgesi',
  },
  {
    value: 'K',
    label: 'K Belgesi',
  },
  {
    value: 'INSPECTION',
    label: 'Muayene Belgesi',
  },
  {
    value: 'RUHSAT',
    label: 'Ruhsat Belge',
  },
];

export const DocumantTypeEnums = {
  INSURANCE: 'Sigorta Belgesi',
  KASKO: 'Kasko Belgesi',
  K: 'K Belgesi',
  INSPECTION: 'Muayene Belgesi',
  RUHSAT: 'Ruhsat Belge',
};
