import { authHeader, request } from '../../helpers';
import moment from 'moment';
interface UploadType {
  brand: string;
  docDescription: string;
  docExpireDate: Date;
  docName: string;
  docType: string;
  ein: string;
  kind: number;
  model: string;
  plate: string;
  tip: string;
  unit: number;
  vehicleId: string;
  vehicleType: string;
  vin: string;
  id: string;
  file: File;
  fileList: File[];
}

function download(id: number): Promise<any> {
  return request({
    url: `/documents/${id}`,
    method: 'GET',
    headers: {
      Accept: 'application/octet-stream',
      ...authHeader(),
    },
    responseType: 'blob',
  });
}

function upload(params: UploadType): Promise<any> {
  const { file, docName, docDescription, docType, docExpireDate } = params;
  const formData = new FormData();
  formData.append('document', file);
  formData.append('name', docName);
  formData.append('type', docType);
  docDescription && formData.append('description', docDescription);
  docExpireDate &&
    formData.append('expiryDate', moment(docExpireDate).format('YYYY-MM-DD'));

  return request({
    url: `/upload/${params.id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authHeader(),
    },
    data: formData,
  });
}

function multipleUpload(params: any, serviceURL: string): Promise<any> {
  const { fileList } = params;
  const formData = new FormData();
  fileList.forEach((file: any) => formData.append('files', file.originFileObj));

  return request({
    url: `/${serviceURL}/upload/${params.id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authHeader(),
    },
    data: formData,
  });
}

function excellDownload(service: string): Promise<any> {
  return request({
    url: `/export/${service}`,
    method: 'GET',
    headers: {
      Accept: 'application/octet-stream',
      ...authHeader(),
    },
    responseType: 'blob',
  });
}

export { download, upload, multipleUpload, excellDownload };
