import { FC, useContext, useState } from 'react';
import moment from 'moment';
import { GlobalContext } from '../../../context/global.context';
import { downloadFile } from '../../../helpers';
import { excellDownload } from '../../../servives/document/document.service';
import { DownloadExcellIcon } from '../../customIcons';
import { CustomButton } from '../../FormElements';
import { DownloadExcellWrapper } from './DownloadExcell.styled';
import { Modal } from 'antd';
import { DatePicker, Space } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';

interface DownloadExcellProps {
  pathName: string;
  text: string;
}

const DownloadExcellWithDate: FC<DownloadExcellProps> = ({
  pathName,
  text,
}: DownloadExcellProps) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [month, setMonth] = useState<any>();
  const [year, setYear] = useState<any>();
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  const handleDownloadExcell = () => {
    increaseLoading();

    setModalVisible(!modalVisible);

    const formatedMonth = moment(month).month();
    const formatedYear = moment(year).year();

    excellDownload(`${pathName}?&year=${formatedYear}&month=${formatedMonth}`)
      .then((resp) => {
        const doc = {
          uri: `${pathName}-table-${moment().format('DD-MM-YYYY')}`,
        };
        downloadFile(resp, doc);
        decreaseLoading();
      })
      .catch((err) => {
        decreaseLoading();
        console.error('ERROR=>', err);
      });
  };

  const handledModal = () => {
    setModalVisible(!modalVisible);
    setMonth(null);
    setYear(null);
  };

  return (
    <>
      <DownloadExcellWrapper>
        <CustomButton
          icon={<DownloadExcellIcon />}
          type="primary"
          onClick={handledModal}
          text={text}
        />
      </DownloadExcellWrapper>
      <Modal
        title={text}
        visible={modalVisible}
        onCancel={handledModal}
        footer={null}
      >
        <Space>
          <DatePicker
            value={month}
            onChange={setMonth}
            picker="month"
            locale={locale}
            format="MMMM"
          />
          <DatePicker
            value={year}
            onChange={setYear}
            picker="year"
            locale={locale}
          />
          <DownloadExcellWrapper>
            <CustomButton
              // icon={<DownloadExcellIcon />}
              type="primary"
              onClick={handleDownloadExcell}
              text="İndir"
              disabled={!month && !year}
            />
          </DownloadExcellWrapper>
        </Space>
      </Modal>
    </>
  );
};

export default DownloadExcellWithDate;
