import React, { FC, useState, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  VehicleService,
  VehicleKindService,
  DocumentService,
} from '../../servives';
import { GlobalContext } from '../../context/global.context';
import { paramTypes } from '../../servives/models/global.model';
import { CreateVehicleModel } from '../../servives/vehicle/vehicle.model';
import { displayFile, downloadFile } from '../../helpers';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
//
import { Form, Row, Col, message, Radio, Collapse, Modal, Tooltip } from 'antd';
import {
  FileAddOutlined,
  FileOutlined,
  DownOutlined,
  UpOutlined,
  DownloadOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import {
  CustomInput,
  CustomButton,
  CustomSelect,
  CustomUpload,
  CustomDatePicker,
} from '../../components/FormElements';
import moment from 'moment';
import {
  ActionWrapper,
  CustomCollapse,
  Document,
  DocumentsWrapper,
  ModalFooter,
} from './Vehicle.styled';
import { DocumantTypeOptionsEnums } from '../../enum/vehicle';
import createSelectOptions from '../../utils/createSelectOptions';
//
interface VehicleTypeData {
  value: number;
  label: string;
}
interface DocsType {
  id: number;
  name: string;
  description: string;
  expiryDate: string;
  type: string;
  uri: string;
}

const VehicleForm: FC = () => {
  const [vehicleKindData, setVehicleKindData] = useState<VehicleTypeData[]>([]);

  const [kaskoDocuments, setKaskoDocuments] = useState<DocsType[]>([]);
  const [insuranceDocuments, setInsuranceDocuments] = useState<DocsType[]>([]);
  const [kdocuments, setKdocuments] = useState<DocsType[]>([]);
  const [extraDocuments, setExtraDocuments] = useState<DocsType[]>([]);
  const [ruhsatDocuments, setRuhsatDocuments] = useState<DocsType[]>([]);
  const [inspectionDocuments, setInspectionDocuments] = useState<DocsType[]>(
    [],
  );
  const [usageType, setUsageType] = useState<string>('');

  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDisable, setisDisable] = useState<boolean>(true);
  const [doc, setDoc] = useState<any>({});

  const { decreaseLoading, increaseLoading, units } = useContext(GlobalContext);
  const history = useHistory();
  const params = useParams<paramTypes>();
  const [form] = Form.useForm();
  const { Panel } = Collapse;

  useEffect(() => {
    getVehicle();
  }, []);

  const getVehicle = () => {
    if (params?.id) {
      increaseLoading();
      // Edit
      VehicleService.getById(params.id).then((resp) => {
        const {
          brand,
          ein,
          id,
          kind,
          model,
          tip,
          plate,
          vehicleId,
          vehicleType,
          unit,
          vin,
          kaskoDocuments,
          insuranceDocuments,
          kdocuments,
          extraDocuments,
          ruhsatDocuments,
          inspectionDocuments,
          kmUsage,
          requiredDocs,
          operator,
          acquisitionCost,
          acquisitionDate,
          maintenancePeriod,
          maintenanceDate,
          consumptionUpperLimit,
          consumptionLowerLimit,
        } = resp.data;

        setKaskoDocuments(kaskoDocuments);
        setInsuranceDocuments(insuranceDocuments);
        setKdocuments(kdocuments);
        setExtraDocuments(extraDocuments);
        setRuhsatDocuments(ruhsatDocuments);
        setInspectionDocuments(inspectionDocuments);
        setUsageType(kmUsage ? 'KM' : 'HOUR');

        handleGetKind(vehicleType).then((kinds: any) => {
          const newKind = kinds.find((item: any) => item.label === kind);

          form.setFieldsValue({
            brand,
            ein,
            id,
            kind: newKind.value,
            model,
            tip,
            plate,
            vehicleId,
            vehicleType,
            unit: unit.id,
            vin,
            requiredDocs: requiredDocs.split(',').map((item: string) => item),
            kmUsage: kmUsage ? 'KM' : 'HOUR',
            operator,
            acquisitionCost,
            acquisitionDate: acquisitionDate ? moment(acquisitionDate) : null,
            maintenancePeriod,
            maintenanceDate: maintenanceDate ? moment(maintenanceDate) : null,
            consumptionUpperLimit,
            consumptionLowerLimit,
          });
          decreaseLoading();
        });
      });
    }
  };

  const handleGetKind = (value: string) => {
    return new Promise((resolve, reject) => {
      VehicleKindService.getAll(value)
        .then((resp) => {
          const kinds = resp.data.list.map((type) => {
            return { value: type.id, label: type.name };
          });
          setVehicleKindData(kinds);
          resolve(kinds);
        })
        .catch(() => {
          console.error('ERROR=> Kind Error');
          message.error('Bir hata oluştu');
          reject('Kind Error');
        });
    });
  };

  const handleSubmit = (value: CreateVehicleModel) => {
    let requiredDocsStr = '';
    requiredDocsStr = value.requiredDocs.join(',');

    value = {
      ...value,
      kmUsage: value.kmUsage === 'KM' ? true : false,
      requiredDocs: requiredDocsStr,
    };
    increaseLoading();
    if (params?.id) {
      VehicleService.vehicleUpdate({ id: params.id, ...value })
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          history.push('/vehicle');
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    } else {
      VehicleService.vehicleCreate(value)
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          history.push(`/vehicle/edit/${resp.data.id}`);
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    }
  };

  const handleRadio = (e: any) => {
    const { value } = e.target;
    handleGetKind(value);
  };

  const handleUsageType = (e: any) => {
    const { value } = e.target;
    setUsageType(value);
  };

  const handleUsagePlasholder = (type: string) => {
    if (type === 'KM') {
      return 'Lt/km';
    } else {
      return 'Lt/saat';
    }
  };

  const handleCollapseKeys = (keys: any) => {
    setActiveKeys(keys);
  };

  const getDoc = (doc: DocsType): Promise<File> =>
    new Promise((resolve, reject) => {
      increaseLoading();
      DocumentService.download(doc.id)
        .then((resp) => {
          resolve(resp);
          message.success('İşlem Başarılı.');
          decreaseLoading();
        })
        .catch(() => {
          reject('Bir Hata Oluştu.');
          message.error('Bir Hata Oluştu.');
          decreaseLoading();
        });
    });

  const handleDownloadFile = async (doc: DocsType) => {
    getDoc(doc).then((resp) => {
      downloadFile(resp, doc);
    });
  };

  const handleDisplayFile = async (doc: DocsType) => {
    getDoc(doc).then((resp) => {
      displayFile(resp);
    });
  };

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
    handleDocFormReset();
  };

  const handleDocSubmit = (val: any) => {
    handleModal();

    increaseLoading();

    form.getFieldsError([
      'docType',
      'docName',
      'docDescription',
      'docFile',
      'docExpireDate',
    ]);

    const docParams = { ...val, id: params.id, ...doc };

    DocumentService.upload(docParams)
      .then((resp) => {
        decreaseLoading();
        message.success(resp.message);
        getVehicle();
      })
      .catch((err) => {
        console.error('ERROR=>', err);
        decreaseLoading();
      });
  };

  const handleDocUpload = (file: any) => {
    setDoc(file);
    setisDisable(false);
  };

  const handleDocFormReset = () => {
    form.resetFields([
      'docType',
      'docName',
      'docDescription',
      'docFile',
      'docExpireDate',
    ]);
  };

  return (
    <>
      <Form
        name="vehicleType"
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
      >
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              name="vehicleId"
              label="Makimsan Ekipman Numarası"
              rules={[
                { required: true, message: 'Lütfen Ekipman Numarası Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Ekipman Numarası" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vehicleType"
              label="Araç Türü"
              rules={[{ required: true, message: 'Lütfen Araç Türü Giriniz!' }]}
            >
              <Radio.Group onChange={handleRadio}>
                <Radio value="VEHICLE">Araç</Radio>
                <Radio value="EQUIPMENT">İş Makinesi</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="kind"
              label="Araç Cinsi"
              rules={[{ required: true, message: 'Lütfen Araç Cinsi Griniz!' }]}
            >
              <CustomSelect
                placeholder="Araç Cinsi"
                options={vehicleKindData}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="brand"
              label="Araç Markasi"
              rules={[
                { required: false, message: 'Lütfen Araç Markasi Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Araç Markasi" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="model"
              label="Araç Modeli"
              rules={[
                { required: false, message: 'Lütfen Araç Model Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Araç Modeli" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tip"
              label="Araç Tipi"
              rules={[
                { required: false, message: 'Lütfen Araç Tipi Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Araç Tipi" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="acquisitionCost"
              label="Araç Edinim Bedeli"
              rules={[
                {
                  required: false,
                  message: 'Lütfen Araç Edinim Bedeli Giriniz!',
                },
              ]}
            >
              <CustomInput type="number" placeholder="Araç Edinim Bedeli" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="acquisitionDate"
              label="Araç Edinim Tarihi"
              rules={[
                {
                  required: false,
                  message: 'Lütfen Araç Edinim Tarih Seçiniz.',
                },
              ]}
            >
              <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="vin"
              label="Şase no"
              rules={[{ required: false, message: 'Lütfen Şase no Giriniz!' }]}
            >
              <CustomInput type="text" placeholder="Şase no" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="ein"
              label="Motor Numarası"
              rules={[
                { required: false, message: 'Lütfen Motor Numarası Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Motor Numarası" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="plate"
              label="Plaka"
              rules={[{ required: false, message: 'Plaka' }]}
            >
              <CustomInput type="text" placeholder="Plaka" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="unit"
              label="Şantiye"
              rules={[{ required: true, message: 'Lütfen Şantiye Seçiniz!' }]}
            >
              <CustomSelect
                disabled={!!params.id}
                placeholder="Şantiye"
                options={createSelectOptions(units, 'name', 'id')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="requiredDocs"
              label="Gerekli Dökümanlar"
              rules={[
                { required: true, message: 'Lütfen Gerekli Döküman Seçiniz!' },
              ]}
            >
              <CustomSelect
                mode="multiple"
                placeholder="Gerekli Dökümanlar"
                options={DocumantTypeOptionsEnums}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="operator"
              label="Şoför Adı"
              rules={[
                { required: false, message: 'Lütfen Şoför Adı Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Şoför Adı" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="kmUsage"
              label="Kullanim Birimi"
              rules={[
                { required: true, message: 'Lütfen Kullanim Birimi Seçiniz!' },
              ]}
            >
              <Radio.Group disabled={!!params.id} onChange={handleUsageType}>
                <Radio value="KM">Km</Radio>
                <Radio value="HOUR">Saat</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>

          {usageType && (
            <>
              <Col span={12}>
                <Form.Item
                  name="maintenancePeriod"
                  label="Rutin Bakım Periyodu"
                  rules={[
                    {
                      required: false,
                      message: 'Lütfen Rutin Bakım Periyodu Giriniz!',
                    },
                  ]}
                >
                  <CustomInput
                    type="number"
                    placeholder={`Kaç ${
                      usageType === 'KM' ? 'Kmde' : 'Saatte'
                    }`}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="maintenanceDate"
                  label="Rutin Bakım Tarihi"
                  rules={[
                    {
                      required: false,
                      message: 'Lütfen Rutin Bakım Tarihi Seçiniz.',
                    },
                  ]}
                >
                  <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="consumptionUpperLimit"
                  label="Yakıt Tüketim Üst Limit"
                  rules={[
                    {
                      required: false,
                      message: 'Lütfen Yakıt Tüketim Üst Limit Giriniz!',
                    },
                  ]}
                >
                  <CustomInput
                    type="text"
                    placeholder={`Yakıt Tüketim Üst Limit ${handleUsagePlasholder(
                      usageType,
                    )}`}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="consumptionLowerLimit"
                  label="Yakıt Tüketim Alt Limit"
                  rules={[
                    {
                      required: false,
                      message: 'Lütfen Yakıt Tüketim Alt Limit Giriniz!',
                    },
                  ]}
                >
                  <CustomInput
                    type="text"
                    placeholder={`Yakıt Tüketim Alt Limit ${handleUsagePlasholder(
                      usageType,
                    )}`}
                  />
                </Form.Item>
              </Col>
            </>
          )}

          <Col span={24}>
            <Form.Item>
              <ActionWrapper>
                <CustomButton type="primary" htmlType="submit">
                  Kaydet
                </CustomButton>
              </ActionWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <DocumentsWrapper>
        <Row>
          <Col span={24}>
            <Form.Item>
              <ActionWrapper>
                <Tooltip
                  placement="rightTop"
                  title="Döküman Yüklemeniz için ilk önce araç kaydı oluşturmanız gerekmetedir."
                >
                  <div>
                    <CustomButton
                      type="primary"
                      onClick={handleModal}
                      disabled={params.id ? false : true}
                    >
                      <FileAddOutlined />
                      Döküman Ekle
                    </CustomButton>
                  </div>
                </Tooltip>
              </ActionWrapper>
            </Form.Item>
          </Col>
        </Row>
        {params.id && (
          //  if has Vechile ID
          <CustomCollapse
            defaultActiveKey={['1']}
            onChange={handleCollapseKeys}
          >
            <Panel
              header="Kasko Belgeleri"
              key="1"
              showArrow={false}
              extra={
                activeKeys.includes('1') ? (
                  <UpOutlined style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )
              }
            >
              {kaskoDocuments.length === 0 && (
                <div>Kasko Belgesi Bulunamadi</div>
              )}
              {kaskoDocuments.map((doc, i) => (
                <Document key={i}>
                  <div className="header">
                    <FileOutlined
                      style={{ fontSize: '42px', color: '#e20e54' }}
                    />
                    <p className="title">
                      {doc.name}
                      <span className="expiryDate">{doc.expiryDate}</span>
                    </p>
                    <span className="description">{doc.description}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        handleDownloadFile(doc);
                      }}
                    >
                      <DownloadOutlined />
                      İndir
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        handleDownloadFile(doc);
                      }}
                    >
                      <DownloadOutlined />
                      İndir
                    </button>
                  </div>
                </Document>
              ))}
            </Panel>
            <Panel
              header="Sigorta Belgeleri"
              key="2"
              showArrow={false}
              extra={
                activeKeys.includes('2') ? (
                  <UpOutlined style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )
              }
            >
              {insuranceDocuments.length === 0 && (
                <div>Sigorta Belgesi Bulunamadi</div>
              )}
              {insuranceDocuments.map((doc, i) => (
                <Document key={i}>
                  <div className="header">
                    <FileOutlined
                      style={{ fontSize: '42px', color: '#e20e54' }}
                    />
                    <p className="title">
                      {doc.name}
                      <span className="expiryDate">{doc.expiryDate}</span>
                    </p>
                    <span className="description">{doc.description}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        handleDisplayFile(doc);
                      }}
                    >
                      <FilePdfOutlined />
                      Görüntüle
                    </button>
                    |
                    <button
                      type="button"
                      onClick={() => {
                        handleDownloadFile(doc);
                      }}
                    >
                      <DownloadOutlined />
                      İndir
                    </button>
                  </div>
                </Document>
              ))}
            </Panel>
            <Panel
              header="K Belgeleri"
              key="3"
              showArrow={false}
              extra={
                activeKeys.includes('3') ? (
                  <UpOutlined style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )
              }
            >
              {kdocuments.length === 0 && <div>K Belgesi Bulunamadi</div>}
              {kdocuments.map((doc, i) => (
                <Document key={i}>
                  <div className="header">
                    <FileOutlined
                      style={{ fontSize: '42px', color: '#e20e54' }}
                    />
                    <p className="title">
                      {doc.name}
                      <span className="expiryDate">{doc.expiryDate}</span>
                    </p>
                    <span className="description">{doc.description}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        handleDisplayFile(doc);
                      }}
                    >
                      <FilePdfOutlined />
                      Görüntüle
                    </button>
                    |
                    <button
                      type="button"
                      onClick={() => {
                        handleDownloadFile(doc);
                      }}
                    >
                      <DownloadOutlined />
                      İndir
                    </button>
                  </div>
                </Document>
              ))}
            </Panel>
            <Panel
              header="Muayene Belgeleri"
              key="4"
              showArrow={false}
              extra={
                activeKeys.includes('4') ? (
                  <UpOutlined style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )
              }
            >
              {inspectionDocuments.length === 0 && (
                <div>Muayene Belgesi Bulunamadi</div>
              )}
              {inspectionDocuments.map((doc, i) => (
                <Document key={i}>
                  <div className="header">
                    <FileOutlined
                      style={{ fontSize: '42px', color: '#e20e54' }}
                    />
                    <p className="title">
                      {doc.name}
                      <span className="expiryDate">{doc.expiryDate}</span>
                    </p>
                    <span className="description">{doc.description}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        handleDisplayFile(doc);
                      }}
                    >
                      <FilePdfOutlined />
                      Görüntüle
                    </button>
                    |
                    <button
                      type="button"
                      onClick={() => {
                        handleDownloadFile(doc);
                      }}
                    >
                      <DownloadOutlined />
                      İndir
                    </button>
                  </div>
                </Document>
              ))}
            </Panel>
            <Panel
              header="Ekstra Belgeleri"
              key="5"
              showArrow={false}
              extra={
                activeKeys.includes('5') ? (
                  <UpOutlined style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )
              }
            >
              {extraDocuments.length === 0 && (
                <div>Ekstra Belge Bulunamadi</div>
              )}
              {extraDocuments.map((doc, i) => (
                <Document key={i}>
                  <div className="header">
                    <FileOutlined
                      style={{ fontSize: '42px', color: '#e20e54' }}
                    />
                    <p className="title">
                      {doc.name}
                      <span className="expiryDate">{doc.expiryDate}</span>
                    </p>
                    <span className="description">{doc.description}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        handleDisplayFile(doc);
                      }}
                    >
                      <FilePdfOutlined />
                      Görüntüle
                    </button>
                    |
                    <button
                      type="button"
                      onClick={() => {
                        handleDownloadFile(doc);
                      }}
                    >
                      <DownloadOutlined />
                      İndir
                    </button>
                  </div>
                </Document>
              ))}
            </Panel>
            <Panel
              header="Ruhsat Belgeleri"
              key="6"
              showArrow={false}
              extra={
                activeKeys.includes('6') ? (
                  <UpOutlined style={{ fontSize: '12px' }} />
                ) : (
                  <DownOutlined style={{ fontSize: '12px' }} />
                )
              }
            >
              {ruhsatDocuments.length === 0 && (
                <div>Ruhsat Belgesi Bulunamadi</div>
              )}
              {ruhsatDocuments.map((doc, i) => (
                <Document key={i}>
                  <div className="header">
                    <FileOutlined
                      style={{ fontSize: '42px', color: '#e20e54' }}
                    />
                    <p className="title">
                      {doc.name}
                      <span className="expiryDate">{doc.expiryDate}</span>
                    </p>
                    <span className="description">{doc.description}</span>
                  </div>
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        handleDisplayFile(doc);
                      }}
                    >
                      <FilePdfOutlined />
                      Görüntüle
                    </button>
                    |
                    <button
                      type="button"
                      onClick={() => {
                        handleDownloadFile(doc);
                      }}
                    >
                      <DownloadOutlined />
                      İndir
                    </button>
                  </div>
                </Document>
              ))}
            </Panel>
          </CustomCollapse>
        )}
      </DocumentsWrapper>
      <Modal
        title="Döküman Ekle"
        visible={isModalVisible}
        onCancel={handleModal}
        footer={null}
      >
        <Form
          name="vehicleDocForm"
          onFinish={handleDocSubmit}
          layout="vertical"
          form={form}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="docType"
                label="Döküman Türü"
                rules={[
                  { required: true, message: 'Lütfen Döküman Türü Seçiniz.' },
                ]}
              >
                <CustomSelect
                  placeholder="Döküman Türü"
                  options={DocumantTypeOptionsEnums}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="docName"
                label="Döküman Adı"
                rules={[
                  {
                    required: true,
                    message: 'Döküman Adı',
                  },
                ]}
              >
                <CustomInput type="text" placeholder="Döküman Adi" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="docDescription" label="Döküman Açıklaması">
                <CustomInput type="text" placeholder="Döküman Açıklaması" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="docExpireDate"
                label="Geçerlilik Süresi"
                rules={[
                  {
                    required: false,
                    message: 'Lütfen Döküman Tarih Seçiniz.',
                  },
                ]}
              >
                <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="docFile" label="Döküman">
                <CustomUpload
                  isDocDisable={false}
                  DocUpload={handleDocUpload}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <ModalFooter>
                <Form.Item>
                  <CustomButton
                    type="primary"
                    htmlType="submit"
                    // disabled={isDisable}
                  >
                    Kaydet
                  </CustomButton>
                </Form.Item>
                <Form.Item style={{ marginLeft: '10px' }}>
                  <CustomButton
                    type="primary"
                    onClick={handleModal}
                    color="dark"
                  >
                    Vazgeç
                  </CustomButton>
                </Form.Item>
              </ModalFooter>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
export default VehicleForm;
