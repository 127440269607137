import React, { FC, useContext, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { VehicleKindService } from '../../../servives';
import { GlobalContext } from '../../../context/global.context';
import { paramTypes } from '../../../servives/models/global.model';

//
import styled from 'styled-components';
import { Form, Row, Col, message, Radio } from 'antd';
import { CustomInput, CustomButton } from '../../../components/FormElements';
//
const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const VehicleTypeForm: FC = () => {
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);
  const history = useHistory();
  const params = useParams<paramTypes>();
  const [form] = Form.useForm();

  useEffect(() => {
    if (params?.id) {
      increaseLoading();
      // Edit
      VehicleKindService.getById(params.id).then((resp) => {
        const { name } = resp.data;
        form.setFieldsValue({
          name,
        });
      });
      decreaseLoading();
    }
  }, []);

  const handleSubmit = (value: { name: string; type: string }) => {
    increaseLoading();
    if (params?.id) {
      VehicleKindService.updateVehicleKind({ id: params.id, ...value })
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          history.push('/vehicle-kind');
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    } else {
      VehicleKindService.createVehicleKind(value)
        .then((resp) => {
          decreaseLoading();
          message.success(resp.message);
          history.push('/vehicle-kind');
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    }
  };
  return (
    <>
      <Form
        name="vehicleType"
        onFinish={handleSubmit}
        form={form}
        layout="vertical"
      >
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              label="Araç Cinsi"
              name="name"
              rules={[
                { required: true, message: 'Lütfen Araç Cinsi Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Araç Açıklaması" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Araç Türü"
              rules={[{ required: true, message: 'Lütfen Araç Türü Seciniz!' }]}
            >
              <Radio.Group>
                <Radio value="VEHICLE">Araç</Radio>
                <Radio value="EQUIPMENT">İş Makinesi</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <ActionWrapper>
                <CustomButton type="primary" htmlType="submit">
                  Kaydet
                </CustomButton>
              </ActionWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default VehicleTypeForm;
