import styled from 'styled-components';
import { Collapse } from 'antd';

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CustomCollapse = styled(Collapse)`
  border-radius: 8px;
  overflow: hidden;

  .ant-collapse-header {
    font-weight: bold;
  }
`;

export const DocumentsWrapper = styled.div`
  border-top: 1px solid #c1c1c1;
  padding-top: 20px;

  .ant-collapse-content-box {
    padding: 6px 16px 16px 16px;
    height: auto;
    max-height: 250px;
    overflow: hidden;
    overflow-y: scroll;
  }
`;

export const Document = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .header {
    display: flex;
    align-items: center;

    .title {
      display: flex;
      flex-direction: column;

      width: 200px;
      margin: 0;
      padding: 0 15px;
      font-weight: bold;
      font-size: 16px;

      line-height: 15px;
      border-right: 1px solid #c1c1c1;

      .expiryDate {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        font-style: italic;
      }
    }
    .description {
      margin-left: 20px;
    }
  }
  button {
    border-radius: 50px;
    background: transparent;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 18px;

    &:hover {
      cursor: pointer;
    }

    span {
      margin-right: 8px;
    }
  }
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NewUser = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 10px;
`;

export const ButtonWrapper = styled.div`
  margin-right: 10px;
`;

export const FilterButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  gap: 10px;
`;
