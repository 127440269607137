import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { GlobalContext } from '../../context/global.context';
import { CheckPermission } from '../../HOC';
import { downloadFile } from '../../helpers';
import moment from 'moment';
import {
  TransferModel,
  fromAndTo,
} from '../../servives/transfer/transfer.model';
import { VehicleModel } from '../../servives/vehicle/vehicle.model';
import { UserDataModel } from '../../servives/user/user.model';
import { TransferService } from '../../servives';
import { paginationPropertiesModel } from '../../servives/models/global.model';
//
import {
  Table,
  Tabs,
  Space,
  Tooltip,
  Button,
  Modal,
  message,
  Drawer,
  Row,
  Col,
  Form,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { CustomButton, CustomSelect } from '../../components/FormElements';
import {
  FilterButtonWrapper,
  ButtonWrapper,
  CreateTranfer,
} from './Transfer.styled';
import { transferStates } from '../../enum/transfer';
import createSelectOptions from '../../utils/createSelectOptions';
//

interface FilterParamsModel {
  unit: string;
}

const Transfer: FC = () => {
  const [transferData, setTransferData] = useState<TransferModel[]>([]);
  const [isDrawerVisible, setIsDrawerVisibile] = useState<boolean>(false);
  const [isSystemManager, setIsSystemManager] = useState<boolean>(false);

  const [filterParams, setFilterParams] = useState<FilterParamsModel>();

  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });

  const SMROLE_ID = 'dc7646ca-ec23-11e9-81b4-2a2ae2dbcce5';

  const [tabKey, setTabKey] = useState<string>('to');
  const {
    increaseLoading,
    decreaseLoading,
    localToken,
    selectedUnitId,
    units,
  } = useContext(GlobalContext);

  const history = useHistory();
  const [form] = Form.useForm();

  const { TabPane } = Tabs;

  const TOTRANSFER = [
    {
      title: 'Sevk Eden Şantiye',
      dataIndex: 'from',
      key: 'from',
      sorter: true,
      render: (from: fromAndTo) => <>{from.name}</>,
    },
    {
      title: 'Sevk Edilen Şantiye',
      dataIndex: 'to',
      key: 'to',
      render: (to: fromAndTo) => <>{to.name}</>,
    },
    {
      title: 'Sevk Tarihi',
      dataIndex: 'transferDate',
      key: 'transferDate',
      render: (transferDate: string) => (
        <>{transferDate && moment(transferDate).format('DD.MM.YYYY')}</>
      ),
    },
    {
      title: 'Sevk Edilen Araç',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (vehicle: VehicleModel) => (
        <>
          <span>{vehicle.vehicleId}</span>
        </>
      ),
    },
    {
      title: 'Sevk Eden Kişi',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy: UserDataModel) => (
        <>
          <span>{createdBy.name}</span>
          <span>{createdBy.surname}</span>
        </>
      ),
    },
    {
      title: 'Sevki Onaylayan Kişi',
      dataIndex: 'approvedBy',
      key: 'approvedBy',
      render: (approvedBy: UserDataModel) => {
        if (approvedBy) {
          return (
            <>
              <span>{approvedBy.name}</span>
              <span>{approvedBy.surname}</span>
            </>
          );
        }
      },
    },

    {
      title: 'Onay Tarihi',
      dataIndex: 'approvalDate',
      key: 'approvalDate',
      render: (approvalDate: string) => (
        <>{approvalDate && moment(approvalDate).format('DD.MM.YYYY')}</>
      ),
    },
    {
      title: 'Sevki Iptal eden Kişi',
      dataIndex: 'cancelledBy',
      key: 'cancelledBy',
      render: (cancelledBy: UserDataModel) => {
        if (cancelledBy) {
          return (
            <>
              <span>{cancelledBy.name}</span>
              <span>{cancelledBy.surname}</span>
            </>
          );
        }
      },
    },
    {
      title: 'İptal Tarihi',
      dataIndex: 'cancelDate',
      key: 'cancelDate',
      render: (cancelDate: string) => (
        <>{cancelDate && moment(cancelDate).format('DD.MM.YYYY')}</>
      ),
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <>
          <b>{transferStates[status].label}</b>
        </>
      ),
    },
    {
      title: '',
      render: (record: TransferModel) =>
        record.status === 'REGISTERED' && (
          <>
            <Space>
              <Tooltip title="Onayla">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CheckCircleOutlined />}
                  onClick={() => {
                    handlehandleTransferApprove(record.id);
                  }}
                />
              </Tooltip>
            </Space>
          </>
        ),
    },
    {
      title: '',
      render: (record: TransferModel) => (
        <>
          <Space>
            <Tooltip title="Pdf indir">
              <Button
                type="primary"
                shape="circle"
                icon={<FilePdfOutlined />}
                onClick={() => {
                  increaseLoading();
                  TransferService.downloadTransferPdf(record.id).then(
                    (resp) => {
                      const doc = {
                        uri: `${record.from.name}-${
                          record.transferDate &&
                          moment(record.transferDate).format('DD-MM-YYYY')
                        }-${record.vehicle.vehicleId}`,
                      };
                      downloadFile(resp, doc);
                      message.success('İşlem Başarılı.');
                      decreaseLoading();
                    },
                  );
                }}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  const FROMTRANSFER = [
    {
      title: 'Sevk Eden Şantiye',
      dataIndex: 'from',
      key: 'from',
      sorter: true,
      render: (from: fromAndTo) => <>{from.name}</>,
    },
    {
      title: 'Sevk Edilen Şantiye',
      dataIndex: 'to',
      key: 'to',
      render: (to: fromAndTo) => <>{to.name}</>,
    },
    {
      title: 'Sevk Tarihi',
      dataIndex: 'transferDate',
      key: 'transferDate',
      render: (transferDate: string) => (
        <>{transferDate && moment(transferDate).format('DD.MM.YYYY')}</>
      ),
    },
    {
      title: 'Sevk Edilen Araç',
      dataIndex: 'vehicle',
      key: 'vehicle',
      render: (vehicle: VehicleModel) => (
        <>
          <span>{vehicle.vehicleId}</span>
        </>
      ),
    },
    {
      title: 'Sevk Eden Kişi',
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy: UserDataModel) => (
        <>
          <span>{createdBy.name}</span>
          <span>{createdBy.surname}</span>
        </>
      ),
    },
    {
      title: 'Sevki Onaylayan Kişi',
      dataIndex: 'approvedBy',
      key: 'approvedBy',
      render: (approvedBy: UserDataModel) => {
        if (approvedBy) {
          return (
            <>
              <span>{approvedBy.name}</span>
              <span>{approvedBy.surname}</span>
            </>
          );
        }
      },
    },

    {
      title: 'Onay Tarihi',
      dataIndex: 'approvalDate',
      key: 'approvalDate',
      render: (approvalDate: string) => (
        <>{approvalDate && moment(approvalDate).format('DD.MM.YYYY')}</>
      ),
    },
    {
      title: 'Sevki Iptal eden Kişi',
      dataIndex: 'cancelledBy',
      key: 'cancelledBy',
      render: (cancelledBy: UserDataModel) => {
        if (cancelledBy) {
          return (
            <>
              <span>{cancelledBy.name}</span>
              <span>{cancelledBy.surname}</span>
            </>
          );
        }
      },
    },
    {
      title: 'İptal Tarihi',
      dataIndex: 'cancelDate',
      key: 'cancelDate',
      render: (cancelDate: string) => (
        <>{cancelDate && moment(cancelDate).format('DD.MM.YYYY')}</>
      ),
    },
    {
      title: 'Durum',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <>
          <b>{transferStates[status].label}</b>
        </>
      ),
    },
    {
      title: '',
      render: (record: TransferModel) =>
        record.status === 'REGISTERED' && (
          <>
            <Space>
              <Tooltip title="iptal Et">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<CloseCircleOutlined />}
                  onClick={() => {
                    handleTransferCancel(record.id);
                  }}
                />
              </Tooltip>
            </Space>
          </>
        ),
    },
    {
      title: '',
      render: (record: TransferModel) => (
        <>
          <Space>
            <Tooltip title="Pdf indir">
              <Button
                type="primary"
                shape="circle"
                icon={<FilePdfOutlined />}
                onClick={() => {
                  increaseLoading();
                  TransferService.downloadTransferPdf(record.id).then(
                    (resp) => {
                      const doc = {
                        uri: `${record.from.name}-${
                          record.transferDate &&
                          moment(record.transferDate).format('DD-MM-YYYY')
                        }-${record.vehicle.vehicleId}`,
                      };
                      downloadFile(resp, doc);
                      message.success('İşlem Başarılı.');
                      decreaseLoading();
                    },
                  );
                }}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    form.setFieldsValue({ unit: selectedUnitId });
  }, [selectedUnitId]);

  useEffect(() => {
    const { roles } = localToken;
    if (roles === SMROLE_ID) {
      setIsSystemManager(true);
      handleDrawerVisibility();
    }
    selectedUnitId !== 0 && getTransferByType('to', '', '');
  }, [localToken, selectedUnitId]);

  const getTransferByType = (
    key: string,
    query?: string,
    filterUnitId?: string,
  ) => {
    increaseLoading();
    TransferService.getAllByUnitId(
      key,
      query,
      filterUnitId,
      selectedUnitId,
    ).then((resp) => {
      decreaseLoading();
      const { list, total } = resp.data;
      setTransferData(list);
      setPaginationProperties((currentState) => ({
        ...currentState,
        total: total,
      }));
    });
  };

  const handleTabChange = (key: string) => {
    getTransferByType(key, '', filterParams?.unit);
    setTabKey(key);
  };

  const handlehandleTransferApprove = (id: number) => {
    Modal.confirm({
      title: 'Uyari!',
      icon: <ExclamationCircleOutlined />,
      content: 'Onaylamak istedeğinize emin misiniz?',
      okText: 'Onayla',
      cancelText: 'Vazgeç',
      onOk: () => {
        TransferService.approveTransfer(id).then(() => {
          getTransferByType('to');
        });
      },
    });
  };

  const handleTransferCancel = (id: number) => {
    Modal.confirm({
      title: 'Uyari!',
      icon: <ExclamationCircleOutlined />,
      content: 'İptal etmek istedeğinize emin misiniz?',
      okText: 'İptal et',
      cancelText: 'Vazgeç',
      onOk: () => {
        TransferService.cancelTransfer(id).then(() => {
          getTransferByType('from');
        });
      },
    });
  };

  const handleNewTransfer = () => {
    history.push('/transfer/add');
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    const query = `&page=${current - 1}&sort=${sortQuery}`;

    getTransferByType(tabKey, query, filterParams?.unit);
  };

  const handleDrawerVisibility = () => {
    setIsDrawerVisibile(!isDrawerVisible);
  };

  // const handleGetVehicleUnits = () => {};

  const handleFilterSubmit = (val: any) => {
    setFilterParams(val);
    getTransferByType(tabKey, '', val.unit);
  };

  const handleResetFilter = () => {
    const { unitIds } = localToken;
    form.setFieldsValue({ unit: unitIds });
    getTransferByType(tabKey, '', '');
  };

  return (
    <>
      <CreateTranfer>
        <CheckPermission authority="CREATE_TRANSFERS">
          <CustomButton
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleNewTransfer}
            text="Ekle"
          />
        </CheckPermission>
      </CreateTranfer>
      {isSystemManager && (
        <FilterButtonWrapper>
          <CustomButton
            icon={<FilterOutlined />}
            type="primary"
            onClick={handleDrawerVisibility}
            text="Filtre"
          />
        </FilterButtonWrapper>
      )}

      <Tabs onChange={handleTabChange} type="card" accessKey={tabKey}>
        <TabPane tab="Gelen Sevk" key="to">
          <Table
            dataSource={transferData}
            columns={TOTRANSFER}
            rowKey="id"
            scroll={{ x: 980 }}
            pagination={paginationProperties}
            onChange={handleTableChange}
          />
        </TabPane>
        <TabPane tab="Giden Sevk" key="from">
          <Table
            dataSource={transferData}
            columns={FROMTRANSFER}
            rowKey="id"
            scroll={{ x: 980 }}
            pagination={paginationProperties}
            onChange={handleTableChange}
          />
        </TabPane>
      </Tabs>
      <Drawer
        title="Filtre"
        placement="right"
        closable={false}
        onClose={handleDrawerVisibility}
        visible={isDrawerVisible}
        width={500}
      >
        <Form
          name="vehicleFilter"
          onFinish={handleFilterSubmit}
          layout="vertical"
          form={form}
        >
          <Row gutter={30}>
            <Col span={24}>
              <Form.Item name="unit" label="Şantiye">
                <CustomSelect
                  placeholder="Şantiye"
                  options={createSelectOptions(units, 'name', 'id')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <FilterButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton type="primary" onClick={handleResetFilter}>
                      Temizle
                    </CustomButton>
                  </ButtonWrapper>
                  <CustomButton type="primary" htmlType="submit">
                    Filtrele
                  </CustomButton>
                </FilterButtonWrapper>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default Transfer;
