import { authHeader, request } from '../../helpers';
import {
  ExpiredDocsModel,
  UnitsComsumptionModel,
  VehicleConflictsModel,
  VehicleCounts,
} from './graph.model';
import { ResponseModel } from '../models/global.model';
import moment from 'moment';

interface VehicleCountsResponse extends ResponseModel {
  data: VehicleCounts[];
}
interface UnitsComsumptionResponse extends ResponseModel {
  data: UnitsComsumptionModel[];
}

interface ExpiredDocsResponse extends ResponseModel {
  data: ExpiredDocsModel[];
}
interface VehicleConflictsResponse extends ResponseModel {
  data: VehicleConflictsModel[];
}

function vehicleCounts(): Promise<VehicleCountsResponse> {
  return request({
    url: `/dashboard/vehiclecounts`,
    method: 'GET',
    headers: authHeader(),
  });
}

function UnitsComsumption(
  dates: moment.Moment[],
): Promise<UnitsComsumptionResponse> {
  return request({
    url: `dashboard/consumption?from=${moment(dates[0]).format(
      'YYYY-MM-DD',
    )}&to=${moment(dates[1]).format('YYYY-MM-DD')}`,
    method: 'GET',
    headers: authHeader(),
  });
}

function VehicleConflicts(): Promise<VehicleConflictsResponse> {
  return request({
    url: `dashboard/conflicts`,
    method: 'GET',
    headers: authHeader(),
  });
}

function ExpiredDocs(): Promise<ExpiredDocsResponse> {
  return request({
    url: `dashboard/expireddocs`,
    method: 'GET',
    headers: authHeader(),
  });
}

export { vehicleCounts, UnitsComsumption, ExpiredDocs, VehicleConflicts };
