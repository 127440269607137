import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

interface ButtonTypes {
  text?: string;
  type?: 'link' | 'text' | 'default' | 'ghost' | 'primary' | 'dashed';
  children?: ReactNode;
  rest?: any;
  icon?: any;
  htmlType?: string;
  color?: string;
  disabled?: boolean;
  onClick?: () => void;
}

const CustomAddButtonStyled = styled(Button)`
  background: #e20e54;
  border-color: transparent;
  border-radius: 4px;
  outline: none;

  &:hover,
  &:focus {
    border-color: transparent;
    background-color: #dc1053;
  }
  background-color: ${(props: ButtonTypes) =>
    props.color === 'dark' ? '#002140' : '#e20e54'};
`;

const CustomAddButton: FC<ButtonTypes> = ({
  children,
  text,
  type,
  rest,
  onClick,
  icon,
  htmlType,
  color,
  disabled,
}: ButtonTypes) => {
  return (
    <CustomAddButtonStyled
      color={color}
      disabled={disabled}
      icon={icon}
      type={type}
      {...rest}
      onClick={onClick}
      htmlType={htmlType}
    >
      {text ? text : children}
    </CustomAddButtonStyled>
  );
};

export default CustomAddButton;
