import React, { FC, useState, useEffect, createContext } from 'react';
import { parseJwt, getToken, ParsedJwtModel } from '../helpers';
import { Unit } from '../servives';
import { UnitModel } from '../servives/unit/unit.model';

export type GlobalContextType = {
  loadingCount: number;
  localToken: ParsedJwtModel;
  increaseLoading: () => void;
  decreaseLoading: () => void;
  handleGetToken: () => void;
  handleSelectedUnit: (unitId: number) => void;
  selectedUnitId: number;
  units: UnitModel[];
  userUnits: UnitModel[];
};

export const GlobalContext = createContext<GlobalContextType>({
  loadingCount: 0,
  localToken: {
    exp: 0,
    iat: 0,
    roles: '',
    scopes: '',
    sub: '',
    units: '',
    name: '',
    surname: '',
    userId: 0,
    unitIds: '0',
  },
  increaseLoading: () => {
    return;
  },
  decreaseLoading: () => {
    return;
  },
  handleGetToken: () => {
    return;
  },
  selectedUnitId: 0,
  handleSelectedUnit: (unitId: number) => {
    return unitId;
  },
  units: [],
  userUnits: [],
});

const GlobalProvider: FC = ({ children }: any) => {
  const [loadingCount, setLoadingCount] = useState<number>(0);
  const [localToken, setLocalToken] = useState<ParsedJwtModel>({
    exp: 0,
    iat: 0,
    roles: '',
    scopes: '',
    sub: '',
    units: '',
    name: '',
    surname: '',
    userId: 0,
    unitIds: '0',
  });
  const [selectedUnitId, setSelectedUnitId] = useState<number>(0);
  const [userUnits, setUserUnits] = useState<UnitModel[]>([]);
  const [units, setUnits] = useState<UnitModel[]>([]);

  useEffect(() => {
    handleGetToken();
  }, []);

  const increaseLoading = (): void => {
    setLoadingCount((loadingCount) => {
      return loadingCount + 1;
    });
  };

  const decreaseLoading = (): void => {
    if (loadingCount >= 0) {
      setLoadingCount((loadingCount) => {
        return loadingCount - 1;
      });
    }
  };

  const handleSelectedUnit = (unitId: number): void => {
    setSelectedUnitId(unitId);
  };

  const handleGetToken = () => {
    const localToken: any = getToken();
    const t: ParsedJwtModel = localToken && parseJwt(localToken);
    if (t) {
      setLocalToken(t);
      setSelectedUnitId(+t.unitIds.split(',')[0]); // Set first unit
      handleUnits(t.unitIds);
    }
  };

  const handleUnits = (userUnits: string) => {
    increaseLoading();
    Unit.getAll()
      .then((resp) => {
        decreaseLoading();
        const { list } = resp.data;
        const userUnitIds = userUnits.split(',');
        const userUnitList: UnitModel[] = [];

        list.forEach((item) => {
          userUnitIds.forEach((id) => {
            if (item.id === +id) {
              userUnitList.push(item);
            }
          });
        });

        setUserUnits(userUnitList);
        setUnits(list);
      })
      .catch((err) => {
        console.error('ERROR=>', err);
        decreaseLoading();
      });
  };

  return (
    <GlobalContext.Provider
      value={{
        loadingCount,
        localToken,
        handleGetToken,
        increaseLoading,
        decreaseLoading,
        selectedUnitId,
        handleSelectedUnit,
        units,
        userUnits,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalProvider;
