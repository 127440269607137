import { useState, useEffect, FC, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { VehicleService, Unit, VehicleKindService } from '../../servives';
import {
  VehicleModel,
  VehicleUsageModel,
} from '../../servives/vehicle/vehicle.model';
import { paginationPropertiesModel } from '../../servives/models/global.model';
import { CheckPermission } from '../../HOC';
import { GlobalContext } from '../../context/global.context';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import {
  Table,
  Button,
  Tooltip,
  Space,
  Modal,
  Row,
  Col,
  Form,
  message,
  Drawer,
  Radio,
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ContainerOutlined,
  ToolOutlined,
  FilterOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import {
  CustomButton,
  CustomSelect,
  CustomInput,
  CustomDatePicker,
} from '../../components/FormElements';
import moment from 'moment';
import {
  ButtonWrapper,
  FilterButtonWrapper,
  ModalFooter,
  NewUser,
} from './Vehicle.styled';
import {
  DownloadExcell,
  DownloadExcellWithDate,
} from '../../components/commons';

interface SelectOptionsModel {
  label: string;
  value: number;
}
interface VehicleTypeData {
  value: number;
  label: string;
}
const Vehicle: FC = () => {
  const [vehicleData, setVechileData] = useState<VehicleModel[]>([]);
  const [vehicleUsageData, setVehicleUsageData] = useState<VehicleUsageModel[]>(
    [],
  );
  const [vehicleEditFormId, setVehicleEditFormId] = useState<number>(0);

  const [unit, setUnit] = useState<SelectOptionsModel[]>([]);
  const [vehicleId, setVehicleId] = useState<number>(0);
  const [isVehicleUsageLoaded, setIsVehicleUsageLoaded] = useState<boolean>(
    false,
  );
  const [selectedVehicle, setSelectedVehicle] = useState<VehicleModel>();
  const [vehicleDataOptions, setVechileDataOptions] = useState<
    SelectOptionsModel[]
  >([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });

  const [isDrawerVisible, setIsDrawerVisibile] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [vehicleKindData, setVehicleKindData] = useState<VehicleTypeData[]>([]);
  const [isSystemManager, setIsSystemManager] = useState<boolean>(false);
  const SMROLE_ID = 'dc7646ca-ec23-11e9-81b4-2a2ae2dbcce5';

  const [form] = Form.useForm();
  const history = useHistory();
  const { search } = useLocation();

  const {
    decreaseLoading,
    increaseLoading,
    localToken,
    selectedUnitId,
  } = useContext(GlobalContext);

  const vehicleTypeEnums: any = {
    VEHICLE: {
      label: 'Araç',
    },
    EQUIPMENT: {
      label: 'İş Makinesi',
    },
  };

  const COLUMS = [
    {
      title: 'Ekipman Numarası',
      dataIndex: 'vehicleId',
      key: 'vehicleId',
      sorter: true,
    },
    {
      title: 'Araç Türü',
      dataIndex: 'vehicleType',
      key: 'vehicleType',
      sorter: true,

      render: (vehicleType: string) => (
        <span>{vehicleTypeEnums[vehicleType].label}</span>
      ),
    },
    {
      title: 'Araç Cinsi',
      dataIndex: 'kind',
      key: 'kind',
      sorter: true,
    },
    {
      title: 'Plaka',
      dataIndex: 'plate',
      key: 'plate',
    },
    {
      title: 'Marka',
      dataIndex: 'brand',
      key: 'brand',
      sorter: true,
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      sorter: true,
    },
    {
      title: 'Şantiye',
      render: (record: VehicleModel) => <>{record.unit.name}</>,
    },
    {
      title: '',
      render: (record: VehicleModel) => (
        <>
          <Space>
            <CheckPermission authority="CREATE_USAGE">
              <Tooltip title="Kullanım Bilgisi">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<ContainerOutlined />}
                  onClick={() => {
                    handleVehicleUsageModal(record.id, record.unit.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="LIST_SERVICES">
              <Tooltip title="Onarım ve Bakım">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<ToolOutlined />}
                  onClick={() => {
                    history.push(`/vehicle/services/${record.id}`);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="UPDATE_VEHICLES">
              <Tooltip title="Düzenle">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleVehicledit(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="DELETE_VEHICLES">
              <Tooltip title="Sil">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleVehicleDelete(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
          </Space>
        </>
      ),
    },
  ];

  const VEHICLEUSAGECOLUMS = [
    {
      title: selectedVehicle?.kmUsage ? 'Toplam Km' : 'Toplam Saat',
      dataIndex: selectedVehicle?.kmUsage ? 'totalKm' : 'totalHours',
      key: selectedVehicle?.kmUsage ? 'totalKm' : 'totalHours',
      render: (totalKm: string) => (
        <span>{`${totalKm} ${selectedVehicle?.kmUsage ? 'Km' : 'Saat'}`}</span>
      ),
    },
    {
      title: 'Tarih',
      dataIndex: 'time',
      key: 'time',
      render: (time: string) => (
        <span>{moment(new Date(time)).format('DD.MM.YYYY')}</span>
      ),
    },
    {
      title: 'Alınan Mazot',
      dataIndex: 'mazot',
      key: 'mazot',
      render: (mazot: string) => <span>{mazot} L</span>,
    },
    {
      title: '',
      render: (record: VehicleUsageModel) => (
        <>
          <Space>
            <CheckPermission authority="UPDATE_USAGE">
              <Tooltip title="Düzenle">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<EditOutlined />}
                  onClick={() => {
                    handleUsageEdit(record.id);
                    setVehicleEditFormId(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
            <CheckPermission authority="DELETE_USAGE">
              <Tooltip title="Sil">
                <Button
                  type="primary"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    handleUsageDelete(record.id);
                  }}
                />
              </Tooltip>
            </CheckPermission>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    selectedUnitId !== 0 && handleGetVehicle(0, '');
  }, [localToken, selectedUnitId]);

  // Open usage information modal if query has a vehicleId and unitId
  useEffect(() => {
    const query = new URLSearchParams(search);
    const vehicleId = Number(query.get('vehicleId'));
    const unitId = Number(query.get('unitId'));

    if (vehicleId && unitId) handleVehicleUsageModal(vehicleId, unitId);
  }, [vehicleData]);

  const handleUsageEdit = (id: number) => {
    const vehicleUsageById: any = vehicleUsageData?.find(
      (item) => item.id === id,
    );

    const {
      vehicle,
      unit,
      time,
      mazot,
      totalKm,
      totalHours,
    } = vehicleUsageById;

    form.setFieldsValue({
      id: vehicleUsageById.id,
      unit: unit.id,
      vehicle: vehicle.id,
      mazot,
      totalKm,
      totalHours,
      time: time && moment(time),
    });
  };

  const handleUsageDelete = (id: number) => {
    Modal.confirm({
      title: 'Uyari!',
      icon: <ExclamationCircleOutlined />,
      content: 'Kullanim bilginisi silmek istedeğinize emin misiniz?',
      okText: 'Sil',
      cancelText: 'Iptal',
      onOk: () => {
        increaseLoading();
        VehicleService.vehicleDeleteUsage(id)
          .then(() => {
            decreaseLoading();
            handleGetVehicleUsage(vehicleId);
          })
          .catch((err) => {
            decreaseLoading();
            message.error(err);
            console.error('ERROR=>', err);
          });
      },
    });
  };

  const handleGetVehicle = (page?: number, sorting?: string) => {
    increaseLoading();

    const { roles } = localToken;
    const query = `${
      SMROLE_ID === roles ? '' : `unit=${selectedUnitId}`
    }&page=${page}${sorting}`;

    VehicleService.getAll(query).then((resp) => {
      decreaseLoading();
      const { list, total } = resp.data;
      const vehicle = list.map((i) => {
        return {
          ...i,
          key: i.id,
        };
      });
      setVechileData(vehicle);
      setPaginationProperties((currentState) => ({
        ...currentState,
        total: total,
      }));
    });
  };

  const handleVehicledit = (id: number) => {
    history.push(`/vehicle/edit/${id}`);
  };

  const handleVehicleDelete = (id: number) => {
    increaseLoading();
    VehicleService.vehicleDelete(id).then(() => {
      handleGetVehicle(currentPage, '');
      decreaseLoading();
    });
  };

  const handleGetVehicleUnits = (unitId?: number) => {
    increaseLoading();
    Unit.getAll()
      .then((resp) => {
        decreaseLoading();
        const { list } = resp.data;
        const units: SelectOptionsModel[] = list.map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setUnit(units);
        unitId && form.setFieldsValue({ unit: unitId });
      })
      .catch((err) => {
        console.error('ERROR=>', err);
        decreaseLoading();
      });
  };

  const handleNewUser = () => {
    history.push(`/vehicle/add`);
  };

  const handleVehicleType = () => {
    history.push(`/vehicle-kind`);
  };

  const handleGetVehicleUsage = (id: number) => {
    setIsVehicleUsageLoaded(true);
    VehicleService.vehicleUsage(id).then((resp) => {
      setIsVehicleUsageLoaded(false);

      const { list } = resp.data;
      const vehicleUsage = list.map((i) => {
        return {
          ...i,
          key: i.id,
        };
      });
      setVehicleUsageData(vehicleUsage);
    });
  };

  const handleVehicleUsageModal = (vehicleId: number, unitId: number) => {
    setVehicleId(vehicleId);
    const selectedVehicle = vehicleData.find(
      (vehicle) => vehicle.id === vehicleId,
    );
    setSelectedVehicle(selectedVehicle);
    handleGetVehicleUnits(unitId);
    handleSetVehicleOptions(vehicleId);
    handleGetVehicleUsage(vehicleId);
    selectedVehicle && setIsModalVisible(true);
  };

  const handleSetVehicleOptions = (vehicleId: number) => {
    const vehicle = vehicleData.map((item) => {
      return {
        label: `${item.vehicleId}`,
        value: item.id,
      };
    });
    setVechileDataOptions(vehicle);
    form.setFieldsValue({ vehicle: vehicleId });
  };

  const handleVehicleUsageModalClose = () => {
    setIsModalVisible(false);
    setVehicleEditFormId(0);
    form.resetFields();
  };

  const disabledTimezoneEffect = (date: Date) => {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
  };

  const handleVehicleUsageSubmit = (val: any) => {
    increaseLoading();
    if (!!vehicleEditFormId) {
      VehicleService.vehicleUpdateUsage({
        id: vehicleEditFormId,
        ...val,
        time: disabledTimezoneEffect(val.time),
      })
        .then((resp) => {
          handleGetVehicleUsage(vehicleId);
          setVehicleEditFormId(0);
          decreaseLoading();
          message.success(resp.message);
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
        });
    } else {
      VehicleService.vehicleUsageCreate({
        ...val,
        time: disabledTimezoneEffect(val.time),
      })
        .then((resp) => {
          handleGetVehicleUsage(vehicleId);
          setVehicleEditFormId(0);
          decreaseLoading();
          message.success(resp.message);
        })
        .catch((err) => {
          console.error('ERROR=>', err);
          decreaseLoading();
          message.error(err);
          // handleVehicleUsageModalClose();
        });
    }
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    setCurrentPage(current);
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    handleGetVehicle(current - 1, `&sort=${sortQuery}`);
  };

  const handleRadio = (e: any) => {
    const { value } = e.target;
    handleGetKind(value);
  };

  const handleGetKind = (value: string) => {
    return new Promise((resolve, reject) => {
      VehicleKindService.getAll(value)
        .then((resp) => {
          const kinds = resp.data.list.map((type) => {
            return { value: type.id, label: type.name };
          });
          setVehicleKindData(kinds);
          resolve(kinds);
        })
        .catch(() => {
          console.error('ERROR=> Kind Error');
          message.error('Bir hata oluştu');
          reject('Kind Error');
        });
    });
  };

  const handleDrawerVisibility = () => {
    setIsDrawerVisibile(!isDrawerVisible);

    if (!isDrawerVisible) {
      const { roles } = localToken;
      handleGetVehicleUnits();
      if (roles === SMROLE_ID) setIsSystemManager(true);
    }
  };

  const handleFilterSubmit = (val: any) => {
    let sortQuery = '';
    Object.keys(val).forEach((key) => {
      sortQuery = `${sortQuery}${val[key] ? `&${key}=${val[key]}` : ''}`;
    });

    handleGetVehicle(0, sortQuery);
  };

  const handleResetFilter = () => {
    form.resetFields();
    handleGetVehicle(0, '');
  };

  const excellApiUrl = history.location.pathname.substring(1);

  return (
    <>
      <NewUser>
        <DownloadExcellWithDate
          text="Aylik dökümü İndir"
          pathName={excellApiUrl}
        />
        <DownloadExcell pathName={excellApiUrl} />
        <CheckPermission authority="READ_VEHICLE_KINDS">
          <CustomButton
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleVehicleType}
            text="Araç Cinsi Ekle"
          />
        </CheckPermission>
        <CustomButton
          icon={<PlusOutlined />}
          type="primary"
          onClick={handleNewUser}
          text="Ekle"
        />
      </NewUser>
      <FilterButtonWrapper>
        <CustomButton
          icon={<FilterOutlined />}
          type="primary"
          onClick={handleDrawerVisibility}
          text="Filtre"
        />
      </FilterButtonWrapper>

      <Table
        dataSource={vehicleData}
        columns={COLUMS}
        onChange={handleTableChange}
        pagination={paginationProperties}
      />

      {isModalVisible && (
        <Modal
          title="Kullanim Bilgisi"
          visible={isModalVisible}
          onCancel={handleVehicleUsageModalClose}
          footer={null}
          width="750px"
        >
          <Form
            name="vehicleDocForm"
            onFinish={handleVehicleUsageSubmit}
            layout="vertical"
            form={form}
          >
            <Row gutter={24} justify="end">
              <Col span={5}>
                {/* TODO: pathname nasil bir url gerekiyorsa yazarsin */}
                <DownloadExcell pathName={excellApiUrl} />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item name="vehicle" label="Araç">
                  <CustomSelect
                    placeholder="Lütfen Araç Seçiniz!"
                    options={vehicleDataOptions}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="unit" label="Şantiye">
                  <CustomSelect
                    placeholder="Lütfen Şantiye Seçiniz!"
                    options={unit}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="totalKm"
                  label="Toplam KM"
                  rules={[
                    {
                      required: selectedVehicle?.kmUsage,
                      message: 'Lütfen Toplam KM Giriniz!',
                    },
                  ]}
                >
                  <CustomInput
                    type="number"
                    placeholder="Toplam KM"
                    disabled={!selectedVehicle?.kmUsage}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="totalHours"
                  label="Toplam Saat"
                  rules={[
                    {
                      required: !selectedVehicle?.kmUsage,
                      message: 'Lütfen Toplam Saat Giriniz!',
                    },
                  ]}
                >
                  <CustomInput
                    type="number"
                    placeholder="Toplam Saat"
                    disabled={selectedVehicle?.kmUsage}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="mazot"
                  label="Mazot Miktari"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen Mazot Miktari Giriniz!',
                    },
                  ]}
                >
                  <CustomInput type="number" placeholder="Mazot Miktari" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="time"
                  label="Kullanım Giriş Tarihi"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen Kullanım Giriş Tarihi Tarih Seçiniz.',
                    },
                  ]}
                >
                  <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <ModalFooter>
                  <Form.Item>
                    <CustomButton type="primary" htmlType="submit">
                      Kaydet
                    </CustomButton>
                  </Form.Item>
                  <Form.Item style={{ marginLeft: '10px' }}>
                    <CustomButton
                      type="primary"
                      onClick={handleVehicleUsageModalClose}
                      color="dark"
                    >
                      Vazgeç
                    </CustomButton>
                  </Form.Item>
                </ModalFooter>
              </Col>
            </Row>
          </Form>

          <Table
            dataSource={vehicleUsageData}
            columns={VEHICLEUSAGECOLUMS}
            loading={isVehicleUsageLoaded}
            scroll={{ y: 200 }}
            pagination={false}
          />
        </Modal>
      )}
      {/* Drawer */}
      <Drawer
        title="Filtre"
        placement="right"
        closable={false}
        onClose={handleDrawerVisibility}
        visible={isDrawerVisible}
        width={500}
      >
        <Form
          name="vehicleFilter"
          onFinish={handleFilterSubmit}
          layout="vertical"
          form={form}
        >
          <Row gutter={30}>
            <Col span={24}>
              <Form.Item name="vehicleId" label="Makimsan Ekipman Numarası">
                <CustomInput type="text" placeholder="Ekipman Numarası" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="vehicleType" label="Araç Türü">
                <Radio.Group onChange={handleRadio}>
                  <Radio value="VEHICLE">Araç</Radio>
                  <Radio value="EQUIPMENT">İş Makinesi</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="kind" label="Araç Cinsi">
                <CustomSelect
                  placeholder="Araç Cinsi"
                  options={vehicleKindData}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="model" label="Araç Modeli">
                <CustomInput type="number" placeholder="Araç Modeli" />
              </Form.Item>
            </Col>
            {isSystemManager && (
              <Col span={24}>
                <Form.Item name="unit" label="Şantiye">
                  <CustomSelect placeholder="Şantiye" options={unit} />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item>
                <FilterButtonWrapper>
                  <ButtonWrapper>
                    <CustomButton type="primary" onClick={handleResetFilter}>
                      Temizle
                    </CustomButton>
                  </ButtonWrapper>
                  <CustomButton type="primary" htmlType="submit">
                    Filtrele
                  </CustomButton>
                </FilterButtonWrapper>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};
export default Vehicle;
