import React, { FC, useContext } from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';
import { authenticationService } from '../../servives';
import { UserProps } from '../../servives/auth/authentication.model';
import { GlobalContext } from '../../context/global.context';
import { RoleManagmentContext } from '../../context/roleManagment.context';
import { ParsedJwtModel } from '../../helpers';

//
import LoadingOverlay from 'react-loading-overlay-ts';
import { Row, Col, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import animationData from '../../assets/lottie/59830-shipping.json';

import logo from '../../assets/images/logo.png';
import {
  LoginSubmitButton,
  LoginWrapper,
  LogoWrapper,
  StyledCard,
} from './Login.styled';

const LoginPage: FC = () => {
  const history = useHistory();
  const {
    decreaseLoading,
    increaseLoading,
    loadingCount,
    handleGetToken,
  } = useContext(GlobalContext);

  const { handleSetRoles } = useContext(RoleManagmentContext);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const onFinish = (values: UserProps) => {
    increaseLoading();
    authenticationService
      .login(values)
      .then((resp: ParsedJwtModel) => {
        decreaseLoading();
        handleSetRoles(resp.scopes);
        handleGetToken();
        history.push(
          resp.scopes
            .split(',')
            .includes('dc7646ca-ec23-11e9-81b4-2a2ae2dbcce5')
            ? '/dashboard'
            : '/vehicle',
        );
      })
      .catch((err) => {
        decreaseLoading();
        console.error('ERROR=>', err);
        message.error(err);
      });
  };

  return (
    <LoadingOverlay
      active={loadingCount > 0}
      spinner
      text="Loading your content..."
    >
      <Row>
        <Col span={14}>
          <LoginWrapper isShowBgImage>
            <Lottie options={lottieOptions} height={500} width={500} />
          </LoginWrapper>
        </Col>
        <Col span={10}>
          <LoginWrapper isShowBg>
            <StyledCard>
              <LogoWrapper>
                <img src={logo} />
              </LogoWrapper>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
              >
                <Form.Item
                  name="tckn"
                  rules={[
                    // eslint-disable-next-line
                    {
                      required: true,
                      message: 'Lütfen kullanıcı adınızı giriniz!',
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    prefix={
                      <UserOutlined
                        style={{ fontSize: '20px', color: '#ffffff' }}
                        className="site-form-item-icon"
                      />
                    }
                    placeholder="Kullanıcı Adı"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    // eslint-disable-next-line
                    { required: true, message: 'Lütfen şifrenizi giriniz!' },
                  ]}
                >
                  <Input
                    prefix={
                      <LockOutlined
                        className="site-form-item-icon"
                        style={{ fontSize: '20px', color: '#ffffff' }}
                      />
                    }
                    type="password"
                    placeholder="Şifre"
                  />
                </Form.Item>
                {/* <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>

                <a className="login-form-forgot" href="">
                  Forgot password
                </a>
              </Form.Item> */}

                <Form.Item>
                  <LoginSubmitButton
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Giriş Yap
                  </LoginSubmitButton>
                  {/* Or <a href="">register now!</a> */}
                </Form.Item>
              </Form>
            </StyledCard>
          </LoginWrapper>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default LoginPage;
