import React, { FC, useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../context/global.context';
import { useHistory } from 'react-router-dom';
import { WarningService } from '../../servives';
import { paginationPropertiesModel } from '../../servives/models/global.model';
import { WarningDataModel } from '../../servives/warnings/warning.model';
import moment from 'moment';
//
import { Row, Col, Table, Tooltip, Button, Space, message } from 'antd';
import { ApiOutlined } from '@ant-design/icons';

const Warning: FC = () => {
  const [warningTableData, setWarningTableData] = useState<WarningDataModel[]>(
    [],
  );
  const history = useHistory();
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);
  const [
    paginationProperties,
    setPaginationProperties,
  ] = useState<paginationPropertiesModel>({
    pageSize: 25,
    total: 0,
  });
  //
  const COLUMS = [
    {
      title: 'Mesaj',
      dataIndex: 'message',
      key: 'message',
      sorter: true,
    },
    // {
    //   title: 'Öncelik',
    //   dataIndex: 'priority',
    //   key: 'priority',
    //   sorter: true,
    // },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      render: (record: any) => (
        <>
          <span>{moment(record).format('DD.MM.YYYY')}</span>
        </>
      ),
    },
    {
      title: '',
      render: (record: any) => (
        <>
          <Space>
            <Tooltip title="Görüntüle">
              <Button
                type="primary"
                shape="circle"
                icon={<ApiOutlined />}
                onClick={() => {
                  handleRedirectByResourceType(
                    record.resourceType,
                    record.resourceId,
                  );
                }}
              />
            </Tooltip>
          </Space>
        </>
      ),
    },
  ];

  useEffect(() => {
    handleGetAllWarning();
  }, []);

  const handleGetAllWarning = (query?: string) => {
    increaseLoading();
    WarningService.getAll(query)
      .then((resp) => {
        const { total, list } = resp.data;
        decreaseLoading();
        const warningTableData: WarningDataModel[] = list.map((warning) => {
          return {
            ...warning,
            key: warning.id,
          };
        });

        setWarningTableData(warningTableData);
        setPaginationProperties((currentState) => ({
          ...currentState,
          total: total,
        }));
      })
      .catch((err) => {
        console.error('ERROR=>', err);
        decreaseLoading();
        message.error(err);
      });
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { current } = pagination;
    const { order, columnKey } = sorter;
    let sortQuery = '';

    if (order === 'descend') {
      sortQuery = `${columnKey},desc`;
    } else if (order === 'ascend') {
      sortQuery = `${columnKey},asc`;
    } else {
      sortQuery = '';
    }

    const query = `&page=${current - 1}&sort=${sortQuery}`;

    handleGetAllWarning(query);
  };

  const handleRedirectByResourceType = (type: string, id: number) => {
    // TODO:  diger typelara gore ayar yapilcak
    const URI = `/${type.toLowerCase()}/edit/${id}`;

    history.push(URI);
  };
  return (
    <>
      <Row gutter={30}>
        <Col span={24}>
          <Table
            dataSource={warningTableData}
            columns={COLUMS}
            onChange={handleTableChange}
            pagination={paginationProperties}
          />
        </Col>
      </Row>
    </>
  );
};

export default Warning;
