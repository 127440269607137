import React, { FC, useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { GlobalContext } from '../../../context/global.context';
import {
  VehicleServicesService,
  VehicleService,
  DocumentService,
} from '../../../servives';
import 'moment/locale/tr';
import locale from 'antd/es/date-picker/locale/tr_TR';
import { VehicleModel } from '../../../servives/vehicle/vehicle.model';
//
import styled from 'styled-components';
import { Form, Row, Col, message } from 'antd';
import {
  CustomInput,
  CustomButton,
  CustomSelect,
  CustomDatePicker,
  CustomUpload,
} from '../../../components/FormElements';
import { VehicleServicesTable } from '../../../components/commons';
//

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ServiceButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 67px;
  align-items: flex-end;
`;

interface SelectOptionsModel {
  label: string;
  value: string;
}
interface vehicleServiceListModel {
  description: string;
  id: number;
  quantity: number;
  unitPrice: number;
}
const servicePlaceOptions: SelectOptionsModel[] = [
  {
    value: 'SERVIS',
    label: 'Servis',
  },
  {
    value: 'ATOLYE',
    label: 'Atolye',
  },
  {
    value: 'SANAYI',
    label: 'Sanayi',
  },
];
const serviceTypeOptions: SelectOptionsModel[] = [
  {
    value: 'BAKIM',
    label: 'Bakım',
  },
  {
    value: 'ONARIM',
    label: 'Onarım',
  },
];
interface paramTypes {
  id?: string;
  vehicleId?: string;
  unitId?: string;
}

const VehicleServiceForm: FC = () => {
  const [vehicleServiceList, setVehicleServiceList] = useState<
    vehicleServiceListModel[]
  >([]);
  const [vehicleData, setVechileData] = useState<VehicleModel>();
  const [doc, setDoc] = useState<any>([]);

  const [form] = Form.useForm();
  const params = useParams<paramTypes>();
  const history = useHistory();
  const { decreaseLoading, increaseLoading } = useContext(GlobalContext);

  useEffect(() => {
    increaseLoading();
    params.id &&
      VehicleService.getById(params.id).then((resp) => {
        decreaseLoading();
        const { data } = resp;
        setVechileData(data);
      });
  }, []);

  const onFinish = (val: any) => {
    increaseLoading();

    delete val.serviceItemsDescription;
    delete val.serviceItemsQuantity;
    delete val.serviceItemsUnitPrice;

    const newVal = {
      ...val,
      serviceItems: vehicleServiceList,
      vehicle: vehicleData?.id,
      unit: vehicleData?.unit.id,
    };

    VehicleServicesService.createService(newVal)
      .then((resp) => {
        const { id } = resp.data;
        if (doc.length === 0) {
          decreaseLoading();
          message.success(resp.message);
          history.push(`/vehicle/services/${vehicleData?.id}`);
        } else {
          DocumentService.multipleUpload({ ...doc, id }, 'services')
            .then((docResp) => {
              decreaseLoading();
              message.success(docResp.message);
              history.push(`/vehicle/services/${vehicleData?.id}`);
            })
            .catch((err) => {
              console.error('ERROR=>', err);
              decreaseLoading();
              message.error(err);
            });
        }
      })
      .catch((err) => {
        decreaseLoading();

        message.error(err);
      });
  };

  const handleServiceItemList = () => {
    form.isFieldsValidating([
      'serviceItemsDescription',
      'serviceItemsQuantity',
      'serviceItemsUnitPrice',
    ]);
    const serviceListFormData = form.getFieldsValue([
      'serviceItemsDescription',
      'serviceItemsQuantity',
      'serviceItemsUnitPrice',
    ]);

    const service = {
      description: serviceListFormData.serviceItemsDescription,
      quantity: serviceListFormData.serviceItemsQuantity++,
      unitPrice: serviceListFormData.serviceItemsUnitPrice++,
    };

    setVehicleServiceList((oldArray: any) => [...oldArray, service]);

    form.resetFields([
      'serviceItemsDescription',
      'serviceItemsQuantity',
      'serviceItemsUnitPrice',
    ]);
  };
  const handleDocUpload = (fileList: any) => {
    setDoc(fileList);
  };

  const handleDeleteServiceItem = (key?: any) => {
    const filterVehicleServiceList = vehicleServiceList.filter(
      (item: any, idx: number) => idx !== key,
    );
    setVehicleServiceList(filterVehicleServiceList);
  };

  return (
    <div>
      <Form name="units" onFinish={onFinish} layout="vertical" form={form}>
        <Row gutter={30}>
          <Col span={12}>
            <Form.Item
              name="serviceType"
              label="Servis Türü"
              rules={[
                { required: true, message: 'Lütfen Servis Türü Seçiniz!' },
              ]}
            >
              <CustomSelect
                options={serviceTypeOptions}
                placeholder="Servis Türü"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="servicePlace"
              label="Servis Yeri"
              rules={[
                { required: true, message: 'Lütfen Servis Yeri Seçiniz!' },
              ]}
            >
              <CustomSelect
                options={servicePlaceOptions}
                placeholder="Servis Yeri"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="serviceAddress"
              label="Servis Adresi"
              rules={[
                { required: true, message: 'Lütfen Servis Adresi Giriniz!' },
              ]}
            >
              <CustomInput type="text" placeholder="Servis Adresi" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="date"
              label="Servis Tarihi"
              rules={[
                { required: true, message: 'Lütfen Servis Tarihi Giriniz!' },
              ]}
            >
              <CustomDatePicker locale={locale} format="DD.MM.YYYY" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="description" label="Açıklama">
              <CustomInput type="text" placeholder="Açıklama" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="docFile" label="Fatura">
              <CustomUpload
                isDocDisable={false}
                DocUpload={handleDocUpload}
                multiple
                accept=".pdf, .png, .jpg, .jpeg"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <hr />
            <h3>Maliyetler</h3>
            <Row gutter={12}>
              <Col span={7}>
                <Form.Item name="serviceItemsDescription" label="Açıklama">
                  <CustomInput type="text" placeholder="Açıklama" />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="serviceItemsQuantity" label="Adet">
                  <CustomInput type="number" placeholder="Adet" />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item name="serviceItemsUnitPrice" label="Birim Fiyatı">
                  <CustomInput type="number" placeholder="Birim Fiyatı" />
                </Form.Item>
              </Col>
              <Col span={3}>
                <Form.Item>
                  <ServiceButtonWrapper>
                    <CustomButton
                      type="primary"
                      onClick={handleServiceItemList}
                    >
                      Ekle
                    </CustomButton>
                  </ServiceButtonWrapper>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <VehicleServicesTable
              serviceList={vehicleServiceList}
              isShowDelete
              handleDeleteServiceItem={handleDeleteServiceItem}
            />
          </Col>
          <Col span={24}>
            <Form.Item>
              <ButtonWrapper>
                <CustomButton
                  type="primary"
                  htmlType="submit"
                  disabled={vehicleServiceList.length > 0 ? false : true}
                >
                  Kaydet
                </CustomButton>
              </ButtonWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default VehicleServiceForm;
