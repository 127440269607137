import React, { FC } from 'react';
//
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
interface CustomUploadType {
  isDocDisable?: boolean;
  multiple?: boolean;
  accept?: string;
  DocUpload: (file: any) => void;
}

const CustomUpload: FC<CustomUploadType> = ({
  isDocDisable,
  multiple = false,
  accept = '.pdf',
  DocUpload,
}: CustomUploadType) => {
  const { Dragger } = Upload;
  return (
    <Dragger
      name="file"
      accept={accept}
      multiple={multiple}
      maxCount={multiple ? 100 : 1}
      beforeUpload={() => {
        return false;
      }}
      onChange={DocUpload}
      disabled={isDocDisable}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Yüklemek için dosyayı tıklayın veya bu alana sürükleyin
      </p>
    </Dragger>
  );
};
export default CustomUpload;
